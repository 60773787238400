import {
  Box,
  HStack,
  Icon,
  Image,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import { useGSAP } from "@gsap/react";
import { RiChatSmile3Line, RiMedalLine, RiServiceLine } from "@remixicon/react";
import gsap from "gsap";
import Lottie from "lottie-react";
import about1Lottie from "../../public/vectors/about1.json";
import about2Lottie from "../../public/vectors/about2.json";
import MemberCard from "../components/Cards/MemberCard";
import Container from "../components/Container";
import PageHeader from "../components/PageHeader";
import TopNav from "../components/TopNav";
import aboutData from "../constant/aboutData";
import landingData from "../constant/landingData";
import memberData from "../constant/memberData";
import useTrigger from "../global/useTrigger";
import Contact from "../landingSections/Contact";
import Footer from "../landingSections/Footer";
import { getLang } from "../lib/lang";
import scrollTriggerConfig from "../lib/scrollTriggerConfig";
import useScreenWidth from "../hooks/useScreenWidth";
import { useRef } from "react";
export default function AboutUsPage() {
  const { trigger } = useTrigger();
  const lang = getLang();
  const data = aboutData[lang];
  const sw = useScreenWidth();
  const aboutUsPageRef = useRef(null);

  // Animation
  useGSAP(
    () => {
      gsap.from(`.right`, {
        scrollTrigger: scrollTriggerConfig("#aboutSection1"),
        x: 300,
        opacity: 0,
      });

      gsap.from(`.left`, {
        scrollTrigger: scrollTriggerConfig("#aboutSection1"),
        x: -300,
        opacity: 0,
      });

      gsap.from(`.right2`, {
        scrollTrigger: scrollTriggerConfig("#aboutSection2"),
        x: 300,
        opacity: 0,
      });

      gsap.from(`.left2`, {
        scrollTrigger: scrollTriggerConfig("#aboutSection2"),
        x: -300,
        opacity: 0,
      });

      gsap.from(".aboutMemberTitle", {
        scrollTrigger: scrollTriggerConfig(".aboutMemberTitle"),
        y: 50,
        opacity: 0,
        stagger: {
          each: 0.2,
        },
      });

      gsap.from(".member", {
        scrollTrigger: scrollTriggerConfig(".members"),
        scale: 0.5,
        opacity: 0,
        stagger: {
          each: 0.2,
        },
      });
    },
    { scope: aboutUsPageRef }
  );

  return (
    <VStack
      ref={aboutUsPageRef}
      id="aboutPage"
      gap={0}
      align={"stretch"}
      overflowX={"clip"}
    >
      <TopNav />

      <Text display={"none"}>{trigger}</Text>

      <PageHeader>{landingData.about[lang].nav}</PageHeader>

      <VStack
        pb={20}
        align={"stretch"}
        overflow={"hidden"}
        position={"relative"}
      >
        <Container flex={1} minH={"500px"} position={"relative"}>
          <SimpleGrid id="aboutSection1" columns={[1, null, 2]} gap={12}>
            <Box className="left" my={"auto"}>
              <Lottie animationData={about1Lottie} loop={true} />
            </Box>

            <VStack
              className="right"
              align={"stretch"}
              justify={"center"}
              gap={0}
            >
              <Text fontSize={32} fontWeight={700} mb={8}>
                {data.intro.title}
              </Text>

              <Text fontSize={20} mb={5} lineHeight={1.5}>
                {data.intro.narrative[0]}
              </Text>

              <Wrap spacing={4}>
                <Icon
                  flex={"0 1 24px"}
                  as={RiChatSmile3Line}
                  fontSize={24}
                  opacity={0.4}
                />
                <Text flex={"1 1 300px"} mb={5} lineHeight={1.7} opacity={0.6}>
                  {data.intro.narrative[1]}
                </Text>
              </Wrap>
            </VStack>
          </SimpleGrid>

          <Stack
            id="aboutSection2"
            flexDir={["column-reverse", null, "row"]}
            gap={12}
            mt={[12, null, 24]}
          >
            <VStack
              w={["100%", null, "50%"]}
              className="left2"
              align={"stretch"}
              justify={"center"}
              gap={0}
            >
              <Text fontSize={20} mb={5} lineHeight={1.5}>
                {data.intro.narrative[2]}
              </Text>

              <Wrap spacing={4}>
                <Icon
                  flex={"0 1 24px"}
                  as={RiMedalLine}
                  fontSize={24}
                  opacity={0.4}
                />
                <Text flex={"1 1 300px"} mb={5} lineHeight={1.7} opacity={0.6}>
                  {data.intro.narrative[3]}
                </Text>
              </Wrap>

              <Wrap spacing={4}>
                <Icon
                  flex={"0 1 24px"}
                  as={RiServiceLine}
                  fontSize={24}
                  opacity={0.4}
                />
                <Text flex={"1 1 300px"} mb={5} lineHeight={1.7} opacity={0.6}>
                  {data.intro.narrative[4]}
                </Text>
              </Wrap>
            </VStack>

            <Box className="right2" my={"auto"} w={["100%", null, "50%"]}>
              <Lottie animationData={about2Lottie} loop={true} />
            </Box>
          </Stack>
        </Container>
      </VStack>

      <VStack
        align={"stretch"}
        // bg={"var(--divider)"}
        pb={20}
      >
        <Container>
          <Text
            className="aboutMemberTitle"
            fontSize={32}
            fontWeight={700}
            mb={8}
          >
            {data.members.title}
          </Text>

          <SimpleGrid
            className="members"
            columns={sw < 350 ? 1 : [2, 3, 4, 5]}
            gap={5}
          >
            {memberData.map((member, i) => (
              <VStack className="member" key={i} h={"100%"} flex={1}>
                <MemberCard data={member} />
              </VStack>
            ))}
          </SimpleGrid>
        </Container>
      </VStack>

      <VStack
        className="techstack"
        w={"100%"}
        mb={20}
        position={"relative"}
        justify={"center"}
        // minH={"500px"}
      >
        <Box w={"100%"}>
          <HStack
            w={"max-content"}
            gap={24}
            className="techstack-moving-banner"
            _hover={{ animationPlayState: "paused" }}
          >
            {aboutData.techstack.map((stack, i) => (
              <Tooltip
                bg={"transparent"}
                color={"current"}
                boxShadow={"none"}
                key={i}
                label={stack.name}
              >
                <Image
                  loading="lazy"
                  alt={`${stack.name} logo`}
                  src={stack.logo}
                  h={"60px"}
                />
              </Tooltip>
            ))}
            {aboutData.techstack.map((stack, i) => (
              <Tooltip
                bg={"transparent"}
                color={"current"}
                boxShadow={"none"}
                key={i}
                label={stack.name}
              >
                <Image alt={`${stack.name} logo`} src={stack.logo} h={"60px"} />
              </Tooltip>
            ))}
            {aboutData.techstack.map((stack, i) => (
              <Tooltip
                bg={"transparent"}
                color={"current"}
                boxShadow={"none"}
                key={i}
                label={stack.name}
              >
                <Image alt={`${stack.name} logo`} src={stack.logo} h={"60px"} />
              </Tooltip>
            ))}
            {aboutData.techstack.map((stack, i) => (
              <Tooltip
                bg={"transparent"}
                color={"current"}
                boxShadow={"none"}
                key={i}
                label={stack.name}
              >
                <Image alt={`${stack.name} logo`} src={stack.logo} h={"60px"} />
              </Tooltip>
            ))}
          </HStack>
        </Box>
      </VStack>

      <Contact />

      <Footer />
    </VStack>
  );
}
