import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  HStack,
  Icon,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useGSAP } from "@gsap/react";
import { RiArrowRightUpLine } from "@remixicon/react";
import gsap from "gsap";
import Lottie from "lottie-react";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Container from "../components/Container";
import PageHeader from "../components/PageHeader";
import TopNav from "../components/TopNav";
import landingData from "../constant/landingData";
import servicesData from "../constant/servicesData";
import { iconSize } from "../constant/sizes";
import useTrigger from "../global/useTrigger";
import Contact from "../landingSections/Contact";
import Footer from "../landingSections/Footer";
import { getLang } from "../lib/lang";
import scrollTriggerConfig from "../lib/scrollTriggerConfig";

export default function ServicesPage() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const section = query.get("section");
  const [activeSection, setActiveSection] = useState<number | null>(null);

  const containerRef = useRef(null);

  useEffect(() => {
    if (section) {
      setActiveSection(parseInt(section as string));
    } else {
      window.scrollTo(0, 0);
    }
  }, [section]);

  const activeRef = useRef<any>(null);
  useEffect(() => {
    if (activeRef.current) {
      activeRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [activeSection]);

  const lang = getLang();
  const sserviceItems = servicesData[lang].items;
  const { trigger } = useTrigger();

  //Animation
  useGSAP(
    () => {
      gsap.from(".serviceItemHeader0", {
        scrollTrigger: scrollTriggerConfig("#serviceItem0"),
        y: -50,
        opacity: 0,
        stagger: {
          each: 0.2,
        },
      });
      gsap.from(".serviceItemImage0", {
        scrollTrigger: scrollTriggerConfig("#serviceItem0"),
        x: -300,
        opacity: 0,
        stagger: {
          each: 0.2,
        },
      });
      gsap.from(".serviceItemBenefit0", {
        scrollTrigger: scrollTriggerConfig("#serviceItem0"),
        x: 300,
        opacity: 0,
        stagger: {
          each: 0.2,
        },
      });

      gsap.from(".serviceItemHeader1", {
        scrollTrigger: scrollTriggerConfig(".serviceItem1"),
        y: -50,
        opacity: 0,
        stagger: {
          each: 0.2,
        },
      });
      gsap.from(".serviceItemImage1", {
        scrollTrigger: scrollTriggerConfig(".serviceItem1"),
        x: -300,
        opacity: 0,
        stagger: {
          each: 0.2,
        },
      });
      gsap.from(".serviceItemBenefit1", {
        scrollTrigger: scrollTriggerConfig(".serviceItem1"),
        x: 300,
        opacity: 0,
        stagger: {
          each: 0.2,
        },
      });

      gsap.from(".serviceItemHeader2", {
        scrollTrigger: scrollTriggerConfig(".serviceItem2"),
        y: -50,
        opacity: 0,
        stagger: {
          each: 0.2,
        },
      });
      gsap.from(".serviceItemImage2", {
        scrollTrigger: scrollTriggerConfig(".serviceItem2"),
        x: -300,
        opacity: 0,
        stagger: {
          each: 0.2,
        },
      });
      gsap.from(".serviceItemBenefit2", {
        scrollTrigger: scrollTriggerConfig(".serviceItem2"),
        x: 300,
        opacity: 0,
        stagger: {
          each: 0.2,
        },
      });
    },
    { scope: containerRef }
  );

  useGSAP(() => {
    gsap.from("#servicePagenarrative", {
      scrollTrigger: scrollTriggerConfig("#servicePagenarrative"),
      y: 50,
      opacity: 0,
    });
  });

  return (
    <VStack
      ref={containerRef}
      id="servicePage"
      align={"stretch"}
      gap={0}
      overflowX={"clip"}
    >
      <TopNav />

      <Text display={"none"}>{trigger}</Text>

      <PageHeader>{landingData.services[lang].nav}</PageHeader>

      <Container pb={16}>
        <VStack gap={8}>
          {sserviceItems.map((service, i) => (
            <Box
              key={i}
              id={`serviceItem${i}`}
              w={"100%"}
              ref={parseInt(section as string) === i ? activeRef : null}
            >
              <VStack
                className={`serviceItem${i}`}
                w={"100%"}
                key={i}
                // bg={"linear-gradient(to bottom, transparent, var(--divider))"}
                borderRadius={24}
                py={8}
                px={[2, null, 8]}
              >
                <VStack
                  className={`serviceItemHeader${i}`}
                  // bg={"linear-gradient(to top, var(--divider), transparent)"}
                  p={4}
                  borderRadius={12}
                  w={"100%"}
                  mb={2}
                >
                  <Icon
                    as={service.icon}
                    fontSize={32}
                    weight={"thin"}
                    opacity={0.5}
                  />
                  <Text fontSize={24} fontWeight={650} textAlign={"center"}>
                    {service.title}
                  </Text>

                  <Text
                    maxW={"800px"}
                    textAlign={"center"}
                    mb={4}
                    opacity={0.5}
                  >
                    {service.narrative}
                  </Text>
                </VStack>

                <SimpleGrid columns={[1, null, 2]} gap={4} w={"100%"}>
                  <VStack justify={"center"} className={`serviceItemImage${i}`}>
                    <Box w={"50%"} maxH={"350px"}>
                      <Lottie animationData={service.iconVector} loop={true} />
                    </Box>
                  </VStack>

                  <VStack
                    className={`serviceItemBenefit${i}`}
                    align={"stretch"}
                    justify={"center"}
                    w={"100%"}
                    maxW={["100%", null, "500px"]}
                    // border={"1px solid var(--divider3)"}
                    // bg={"var(--divider)"}
                    borderRadius={12}
                    gap={0}
                  >
                    <VStack p={5} pb={0} align={"stretch"}>
                      <Text
                        fontSize={20}
                        fontWeight={700}
                        textAlign={["center", null, "left"]}
                      >
                        {service.benefit?.title}
                      </Text>

                      <Accordion allowMultiple>
                        {service.benefit?.items.map((benefit, i) => (
                          <AccordionItem
                            key={i}
                            borderTop={i === 0 ? "none" : ""}
                            borderColor={"var(--divider3)"}
                            borderBottom={
                              //@ts-ignore
                              i === service.benefit?.items.length - 1
                                ? "none"
                                : ""
                            }
                          >
                            <h2>
                              <AccordionButton
                                py={4}
                                px={0}
                                _hover={{ bg: "transparent" }}
                              >
                                <Box as="span" flex="1" textAlign="left">
                                  {benefit.title}
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                            </h2>

                            <AccordionPanel
                              pb={4}
                              opacity={0.5}
                              lineHeight={1.6}
                              px={0}
                              transition={"200ms !important"}
                            >
                              {benefit.narrative}
                            </AccordionPanel>
                          </AccordionItem>
                        ))}
                      </Accordion>
                    </VStack>

                    <Button
                      transition={"200ms"}
                      w={["100%", null, "fit-content"]}
                      rightIcon={
                        <Icon as={RiArrowRightUpLine} fontSize={iconSize} />
                      }
                      bg={"transparent"}
                      h={"50px"}
                      _hover={{
                        bg: "var(--p500a4)",
                      }}
                      color={"p.500"}
                      pr={5}
                      mt={4}
                      as={Link}
                      className="clicky"
                      to={`/works?category=${i + 1}&search=`}
                    >
                      {lang === "id"
                        ? "Lihat karya layanan ini"
                        : "View this service portfolio"}
                    </Button>
                  </VStack>
                </SimpleGrid>
              </VStack>
            </Box>
          ))}
        </VStack>

        <HStack
          id="servicePagenarrative"
          p={4}
          borderRadius={12}
          mt={14}
          mb={10}
        >
          <Text textAlign={"center"} maxW={"800px"} mx={"auto"} fontSize={18}>
            {lang === "id"
              ? "Dengan layanan ini, kami berharap dapat membantu perusahaan Anda tumbuh dan mencapai tujuan bisnisnya."
              : "With this service, we hope to help your company grow and achieve its business goals."}
          </Text>
        </HStack>
      </Container>

      <Contact />

      <Footer />
    </VStack>
  );
}
