import {
  Box,
  Button,
  Center,
  HStack,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logoLottie from "../../public/vectors/logoColor.json";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import landingData from "../constant/landingData";
import useScreenWidth from "../hooks/useScreenWidth";
import { getLang } from "../lib/lang";
import LangSwitcher from "./LangSwitcher";
import NavDrawer from "./Navs/DrawerNav";

export default function TopNav(props: any) {
  const sw = useScreenWidth();
  const lang = getLang();

  const [scrollDirection, setScrollDirection] = useState<"up" | "down">("up");
  const [scrollYPos, setScrollYPos] = useState<number>(window.scrollY);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (scrollYPos < currentScrollY) {
      setScrollDirection("down");
    } else {
      setScrollDirection("up");
    }

    setScrollYPos(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollYPos]);

  return (
    <Box
      id="topNav"
      animation={"float-in-from-top 0.5s"}
      zIndex={99}
      position={"sticky"}
      w={"100%"}
      top={0}
      left={0}
      borderBottom={"1px solid var(--divider3)"}
      transform={scrollDirection === "up" ? "" : "translateY(-80px)"}
      bg={useColorModeValue("whiteAlpha.800", "#191919dd")}
      backdropFilter={"blur(5px)"}
      transition={"200ms"}
      {...props}
    >
      <HStack
        justify={"space-between"}
        py={4}
        px={[5, null, 6]}
        w={"100%"}
        maxW={"1440px"}
        h={"72px"}
        mx={"auto"}
        // borderRadius={12}
      >
        {sw > 955 ? (
          <HStack gap={0} w={[null, null, "200px"]}>
            {landingData.landingNav[lang]?.map((nav, i) => {
              const ok = i > 0;

              return (
                ok && (
                  <Button
                    as={Link}
                    to={nav.link}
                    key={i}
                    flexShrink={0}
                    fontWeight={600}
                    className="btn-clear"
                    _hover={{ color: "p.500", transform: "translateY(-2px)" }}
                    transition={"200ms"}
                    px={3}
                    color={props?.color || "current"}
                  >
                    <Text fontSize={14}>{nav.name}</Text>
                  </Button>
                )
              );
            })}
          </HStack>
        ) : (
          ""
        )}

        <VStack position={"relative"} flexShrink={0}>
          <Center
            className="sm-clicky"
            as={Link}
            to={"/"}
            transition={"200ms"}
            w={"100%"}
            maxW={"55px"}
            borderRadius={"full"}
            overflow={"clip"}
          >
            <Lottie animationData={logoLottie} loop={true} width={"100%"} />
          </Center>
        </VStack>

        <HStack
          gap={0}
          flexShrink={0}
          w={[null, null, "200px"]}
          justify={"flex-end"}
        >
          {sw > 350 && (
            <>
              <ColorModeSwitcher
                ml={0}
                color={props?.color || "current"}
                borderRadius={"full"}
              />

              <LangSwitcher
                color={props?.color || "current"}
                borderRadius={"full"}
              />
            </>
          )}

          <Button
            ml={2}
            className="btn-bnw clicky"
            colorScheme="bnw"
            borderRadius={"full"}
            flexShrink={0}
            h={"36px"}
            as={Link}
            to={"/contact"}
          >
            {lang === "id" ? "Hubungi Kami" : "Contact Us"}
          </Button>

          {sw <= 955 && (
            <NavDrawer
              color={props?.color || "current"}
              aria-label="Nav Drawer"
              ml={2}
            />
          )}
        </HStack>
      </HStack>
    </Box>
  );
}
