import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  SimpleGrid,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import Container from "../components/Container";
import SectionNav from "../components/SectionNav";
import landingData from "../constant/landingData";

import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import Lottie from "lottie-react";
import { useRef, useState } from "react";
import { getLang } from "../lib/lang";
import scrollTriggerConfig from "../lib/scrollTriggerConfig";

export default function About() {
  const lang = getLang();
  const [activeImage, setActiveImage] = useState<any>(
    landingData.about[lang].items[0].image
  );

  const containerRef = useRef(null);

  // Animation
  useGSAP(
    () => {
      gsap.from([".sectionNavLabel", ".sectionTitle", "#carouselControl"], {
        scrollTrigger: scrollTriggerConfig("#aboutContent"),
        y: 50,
        opacity: 0,
        stagger: {
          each: 0.2,
        },
      });
      gsap.from([`.aboutItem`], {
        scrollTrigger: scrollTriggerConfig("#aboutItemContainer"),
        x: -300,
        opacity: 0,
        // ease: "back",
        stagger: {
          each: 0.2,
        },
      });
      gsap.from(`.aboutImage`, {
        scrollTrigger: scrollTriggerConfig("#aboutImageContainer"),
        x: 300,
        opacity: 0,
        // ease: "back",
        stagger: {
          each: 0.2,
        },
      });
    },
    { scope: containerRef }
  );

  return (
    <VStack ref={containerRef} id="about" align={"stretch"} pt={20} gap={0}>
      <Container transition={"200ms"} mb={12}>
        <Wrap justify={"space-between"} spacing={4}>
          <VStack
            align={"stretch"}
            className="sectionHeader"
            mx={["auto", null, null, 0]}
          >
            <VStack align={"stretch"} className={"sectionNavLabel"}>
              <SectionNav
                mx={["auto", null, null, 0]}
                link={landingData.landingNav.id[3].link}
              >
                {landingData.about[lang].nav}
              </SectionNav>
            </VStack>

            <Heading
              as={"h1"}
              className="sectionTitle"
              textAlign={["center", null, "left"]}
            >
              {landingData.about[lang].title}
            </Heading>
          </VStack>
        </Wrap>
      </Container>

      <Container id={"aboutContent"}>
        <SimpleGrid columns={[1, null, null, 2]} gap={8}>
          <VStack
            id="aboutItemContainer"
            // justify={"center"}
            minH={"450px"}
            align={"stretch"}
            gap={5}
            order={[1, null, null, 0]}
          >
            <Accordion allowToggle defaultIndex={[0]}>
              {landingData.about[lang].items.map((item, i) => (
                <AccordionItem
                  key={i}
                  className="aboutItem"
                  borderBottom={
                    i < landingData.about[lang].items.length - 1
                      ? "1px solid var(--divider3)"
                      : "none"
                  }
                  borderTop={"none"}
                >
                  <h2>
                    <AccordionButton
                      px={0}
                      py={6}
                      className="btn-clear"
                      onClick={() => {
                        setActiveImage(item.image);
                      }}
                    >
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontSize={24}
                        fontWeight={600}
                      >
                        {item.title}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={8} px={0} opacity={0.6}>
                    {item.narrative}
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </VStack>

          <VStack
            id="aboutImageContainer"
            align={"stretch"}
            gap={3}
            order={[0, null, null, 1]}
          >
            <Box
              flex={1}
              mt={[0, null, null, -10]}
              ml={"auto"}
              mr={["auto", null, null, 8]}
              className="aboutImage"
              maxH={"500px"}
              w={"100%"}
              maxW={"360px"}
            >
              <Lottie animationData={activeImage} loop={true} height={"100%"} />
            </Box>
            W
          </VStack>
        </SimpleGrid>
      </Container>
    </VStack>
  );
}
