import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useGSAP } from "@gsap/react";
import { RiArrowRightLine } from "@remixicon/react";
import gsap from "gsap";
import Lottie from "lottie-react";
import { Link } from "react-router-dom";
import ideaLottie from "../../public/vectors/idea.json";
import Container from "../components/Container";
import { useBnWColor, useWnBColor } from "../constant/colors";
import landingData from "../constant/landingData";
import useScreenHeight from "../hooks/useScreenHeight";
import { getLang } from "../lib/lang";
import useScreenWidth from "../hooks/useScreenWidth";
import { useRef } from "react";

export default function Hero() {
  const lang = getLang();
  const data = landingData?.hero?.[lang];
  const containerRef = useRef(null);

  // SX
  const wnb = useWnBColor();
  const sh = useScreenHeight();
  const sw = useScreenWidth();

  // Animation
  useGSAP(
    () => {
      gsap.from(".heroInit", {
        y: 50,
        opacity: 0,
        stagger: {
          each: 0.1,
        },
      });
    },
    { scope: containerRef }
  );

  return (
    <VStack
      ref={containerRef}
      id="hero"
      align={"stretch"}
      flex={1}
      gap={0}
      minH={sh < 1280 ? "100vh" : "924px"}
      overflowY={"clip"}
      pt={"72px"}
      mt={"-72px"}
    >
      <Container flex={1} position={"relative"}>
        <Image
          src={"/vectors/lightning2.svg"}
          position={"absolute"}
          top={"-72px"}
          right={["10px", null, "-300px", "-200px"]}
          maxH={"900px"}
          zIndex={-1}
          className="no-lazy"
          opacity={0.1}
        />

        <HStack
          flexDir={["column-reverse", null, "row"]}
          align={"stretch"}
          flex={1}
          py={4}
          gap={0}
        >
          <VStack
            px={["", null, 6]}
            pt={["", null, 8]}
            pb={16}
            w={["100%", null, "55%"]}
            align={["center", null, "stretch"]}
            flex={1}
            justify={"center"}
            zIndex={2}
          >
            <HStack mb={2} className="heroInit">
              <Box w={"30px"} h={"1px"} bg={"p.500"} />
              <Text color={"p.500"} textAlign={["center", null, "left"]}>
                Distro Studio
              </Text>
              {sw < 768 && <Box w={"30px"} h={"1px"} bg={"p.500"} />}
            </HStack>

            <Heading
              className="display heroInit"
              fontWeight={500}
              lineHeight={1.2}
              fontSize={[36, null, 56]}
              mb={6}
              textAlign={["center", null, "left"]}
            >
              {data?.headline}
            </Heading>

            <Text
              fontSize={[16, null, 20]}
              mb={6}
              className="heroInit"
              opacity={0.6}
              textAlign={["center", null, "left"]}
            >
              {data.narrative}
            </Text>

            <Box className="heroInit" w={"100%"}>
              <Button
                flexShrink={0}
                className="btn-ap clicky"
                pl={8}
                pr={7}
                h={"60px"}
                w={["100%", null, "fit-content"]}
                as={Link}
                to={"/contact"}
              >
                <HStack justify={["center", null, "space-between"]} w={"100%"}>
                  <Text pr={2} fontSize={18}>
                    {landingData.hero[lang].letsTalk.label}
                  </Text>
                  <Icon as={RiArrowRightLine} fontSize={24} color={"white"} />
                </HStack>
              </Button>
            </Box>
          </VStack>

          <VStack
            w={["100%", null, "45%"]}
            px={[0, null, 6]}
            justify={"center"}
            className="heroInit"
            position={"relative"}
            zIndex={1}
            mb={[8, null, 0]}
            minH={["", null, "500px"]}
            align={"start"}
          >
            <Box w={["100%", null, "600px"]} ml={["-10px", null, "-50px"]}>
              <Lottie animationData={ideaLottie} loop={true} />
            </Box>
          </VStack>
        </HStack>
      </Container>

      <Box bg={useBnWColor()}>
        <Container>
          <SimpleGrid
            columns={[2, 2, 4]}
            px={4}
            py={8}
            gap={8}
            // maxW={["", null, "720px"]}
          >
            {landingData.stats[lang].map((stat, i) => {
              return (
                <VStack
                  key={i}
                  className="heroInit"
                  align={"center"}
                  gap={0}
                  color={wnb}
                >
                  <HStack>
                    <Icon
                      as={stat.icon}
                      fontSize={[28, 32, 48]}
                      opacity={0.6}
                    />
                    <Text fontSize={[28, 32, 48]} fontWeight={650}>
                      {stat.value}
                    </Text>
                  </HStack>
                  <Text opacity={0.6}>{stat.name}</Text>
                </VStack>
              );
            })}
          </SimpleGrid>
        </Container>
      </Box>
    </VStack>
  );
}
