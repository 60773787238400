import {
  Center,
  Link as ChakraLink,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import Container from "../components/Container";
import landingData from "../constant/landingData";

import { useGSAP } from "@gsap/react";
import { RiMailFill, RiPhoneFill } from "@remixicon/react";
import gsap from "gsap";
import { Link } from "react-router-dom";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import LangSwitcher from "../components/LangSwitcher";
import { useBnWColor, useWnBColor } from "../constant/colors";
import { iconSize } from "../constant/sizes";
import { getLang } from "../lib/lang";
import scrollTriggerConfig from "../lib/scrollTriggerConfig";
import { useRef } from "react";

export default function Footer() {
  const lang = getLang();
  const containerRef = useRef(null);

  useGSAP(
    () => {
      gsap.from(".footerItem", {
        scrollTrigger: scrollTriggerConfig("#footerContent"),
        scale: 0.5,
        opacity: 0,
        stagger: {
          each: 0.2,
        },
      });
    },
    { scope: containerRef }
  );

  return (
    <VStack
      ref={containerRef}
      id={"footer"}
      align={"stretch"}
      bg={useBnWColor()}
      color={useWnBColor()}
      position={"relative"}
      overflow={"clip"}
    >
      <VStack
        id="footerContent"
        gap={0}
        align={"stretch"}
        // bg={"linear-gradient(to bottom, black, transparent)"}
        zIndex={2}
      >
        <Container zIndex={2} maxW={"1440px"} mt={20}>
          <Stack flexDir={["column", null, "row"]} gap={8}>
            <VStack
              className="footerItem"
              w={["100%", null, "65%"]}
              align={"stretch"}
              gap={0}
              // bg={"blackAlpha.800"}
              backdropFilter={"blur(5px)"}
              borderRadius={24}
              // p={10}
            >
              <VStack align={"stretch"} mb={20}>
                <Image
                  alt="Logo Kreasi Teknologi Kini"
                  loading={"lazy"}
                  src="/images/companyLogoColor.png"
                  h={"50px"}
                  objectFit={"contain"}
                  mr={"auto"}
                  mb={4}
                />

                <Heading fontSize={20}>
                  {landingData.footer[lang].companyName}
                </Heading>

                <HStack>
                  <Icon as={RiMailFill} fontSize={iconSize} />
                  <Text>{landingData.footer[lang].email}</Text>
                </HStack>

                <HStack>
                  <Icon as={RiPhoneFill} fontSize={iconSize} />
                  <Text>{landingData.footer[lang].phone}</Text>
                </HStack>
              </VStack>

              <SimpleGrid columns={[1, null, null, null, 2]} gap={20}>
                <VStack align={"stretch"}>
                  <Text fontWeight={600}>
                    {landingData.footer[lang].narrative.title}
                  </Text>

                  <Text opacity={0.6}>
                    {landingData.footer[lang].narrative.description}
                  </Text>
                </VStack>

                <SimpleGrid columns={[1, 2]} gap={20}>
                  <VStack align={"stretch"} gap={4}>
                    <Heading as={"h3"} fontSize={16} mb={4}>
                      {landingData.footer[lang].navs.company.label}
                    </Heading>
                    {landingData.footer[lang].navs.company.items.map(
                      (nav, i) => (
                        <Text
                          key={i}
                          as={Link}
                          to={nav.link}
                          w={"fit-content"}
                          fontWeight={400}
                          _hover={{ opacity: 0.5 }}
                        >
                          {nav.label}
                        </Text>
                      )
                    )}
                  </VStack>

                  <VStack align={"stretch"} gap={4}>
                    <Heading as={"h3"} fontSize={16} mb={4}>
                      {landingData.footer[lang].navs.links.label}
                    </Heading>
                    <Text
                      as={Link}
                      to={"/contact"}
                      w={"fit-content"}
                      fontWeight={400}
                      _hover={{ opacity: 0.5 }}
                    >
                      {lang === "id" ? "Hubungi Kami" : "Contact Us"}
                    </Text>
                    {landingData.footer[lang].navs.links.items.map((nav, i) => (
                      <Text
                        key={i}
                        as={Link}
                        to={nav.link}
                        w={"fit-content"}
                        fontWeight={400}
                        _hover={{ opacity: 0.5 }}
                      >
                        {nav.label}
                      </Text>
                    ))}
                  </VStack>
                </SimpleGrid>
              </SimpleGrid>
            </VStack>

            <VStack
              id="sosmeds"
              w={["100%", null, "35%"]}
              align={"stretch"}
              gap={3}
            >
              <Wrap justify={"space-between"} gap={3}>
                {landingData.sosmeds.map((sosmed, i) => (
                  <Center className="footerItem" key={i}>
                    <IconButton
                      as={ChakraLink}
                      href={sosmed.link}
                      isExternal
                      className="btn clicky"
                      borderRadius={24}
                      colorScheme="bnw"
                      size={"lg"}
                      aria-label={sosmed.name}
                      icon={<Icon as={sosmed.icon} fontSize={32} />}
                    />
                  </Center>
                ))}
              </Wrap>

              <VStack
                className="footerItem"
                align={"stretch"}
                // bg={"blackAlpha.800"}
                backdropFilter={"blur(5px)"}
                borderRadius={24}
                flex={1}
              >
                <VStack py={10} align={"stretch"}>
                  <Text fontSize={20} fontWeight={600} mb={4}>
                    {lang === "id"
                      ? "Ayo bekerja bersama"
                      : "Let's work together"}
                  </Text>

                  <Text mb={4} opacity={0.6}>
                    {landingData.footer[lang].location}
                  </Text>
                </VStack>

                <VStack
                  bgImage={"/images/footer/semarang.png"}
                  bgSize={"cover"}
                  bgPos={"center"}
                  w={"100%"}
                  h={"100%"}
                  minH={"400px"}
                  flex={1}
                  align={"stretch"}
                  borderRadius={12}
                >
                  <VStack
                    flex={1}
                    as={ChakraLink}
                    href={
                      "https://www.google.com/maps/place/Semarang,+Semarang+City,+Central+Java/@-7.0243604,110.2525998,11z/data=!3m1!4b1!4m6!3m5!1s0x2e708b4d3f0d024d:0x1e0432b9da5cb9f2!8m2!3d-6.9818006!4d110.4120729!16zL20vMDQ5Nm1o?entry=ttu"
                    }
                    isExternal
                    // bg={"linear-gradient(to bottom, blackAlpha.800, transparent)"}
                  ></VStack>
                </VStack>
              </VStack>
            </VStack>
          </Stack>

          <SimpleGrid
            className="footerItem"
            columns={[1, null, 2]}
            mb={4}
            gap={5}
            mt={3}
            // bg={"blackAlpha.800"}
            backdropFilter={"blur(5px)"}
            borderRadius={24}
            // px={10}
            py={5}
          >
            <Text
              my={"auto"}
              mx={["auto", null, "0"]}
              textAlign={["center", null, "left"]}
            >
              2023 © Distro Studio. All right reserved.
            </Text>

            <HStack justify={["", null, "flex-end"]} mx={["auto", null, "0"]}>
              <ColorModeSwitcher
                fontSize={20}
                color={useWnBColor()}
                className="btn"
              />

              <LangSwitcher color={useWnBColor()} />
            </HStack>
          </SimpleGrid>
        </Container>
      </VStack>
    </VStack>
  );
}
