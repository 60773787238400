import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import useTrigger from "../global/useTrigger";
import { getLang } from "../lib/lang";
import PageHeader from "../components/PageHeader";
import Container from "../components/Container";
import landingData from "../constant/landingData";
import Contact from "../landingSections/Contact";
import Footer from "../landingSections/Footer";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import TopNav from "../components/TopNav";

export default function FaqsPage() {
  const { trigger } = useTrigger();
  const lang = getLang();

  //Animation
  useGSAP(() => {
    gsap.from(".faqsItem", {
      y: 50,
      opacity: 0,
      stagger: {
        each: 0.2,
      },
    });
  });

  return (
    <VStack gap={0} align={"stretch"} overflowX={"clip"}>
      <TopNav />

      <Text display={"none"}>{trigger}</Text>

      <PageHeader>{"FAQs"}</PageHeader>

      <VStack gap={0} align={"stretch"} pb={20}>
        <Container>
          <Accordion defaultIndex={[0]} allowMultiple>
            {landingData.contact[lang].faqs.map((faq, i) => (
              <AccordionItem
                className="faqsItem"
                key={i}
                borderTop={i === 0 ? "none" : ""}
                borderBottom={
                  i === landingData.contact[lang].faqs.length - 1 ? "none" : ""
                }
                borderColor={"var(--divider3)"}
              >
                <h2>
                  <AccordionButton
                    py={6}
                    px={0}
                    _hover={{ bg: "transparent" }}
                    as={HStack}
                    cursor={"pointer"}
                  >
                    <Box
                      fontWeight={550}
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontSize={22}
                    >
                      {faq.title}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel opacity={0.6} lineHeight={1.6} px={0}>
                  {faq.description}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Container>
      </VStack>

      <Contact />

      <Footer />
    </VStack>
  );
}
