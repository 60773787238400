import {
  Box,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useGSAP } from "@gsap/react";
import { RiArrowRightSLine } from "@remixicon/react";
import gsap from "gsap";
import Lottie from "lottie-react";
import { Link } from "react-router-dom";
import precisionAndQualityLottie from "../../public/vectors/precisionAndQuality.json";
import Container from "../components/Container";
import SectionNav from "../components/SectionNav";
import landingData from "../constant/landingData";
import servicesData2 from "../constant/servicesData";
import { getLang } from "../lib/lang";
import scrollTriggerConfig from "../lib/scrollTriggerConfig";
import { useRef } from "react";

export default function Services() {
  const lang = getLang();
  const data = servicesData2[lang];
  const containerRef = useRef(null);

  // Animation
  useGSAP(
    () => {
      gsap.from([".sectionNavLabel", ".sectionTitle"], {
        scrollTrigger: scrollTriggerConfig("#servicesContent"),
        y: 50,
        opacity: 0,
        stagger: {
          each: 0.2,
        },
      });
      gsap.from(`.serviceItem`, {
        scrollTrigger: scrollTriggerConfig("#servicesContent"),
        scale: 0.5,
        opacity: 0,
        stagger: {
          each: 0.2,
        },
      });
    },
    { scope: containerRef }
  );

  return (
    <Container pt={20} id="services">
      <SimpleGrid
        ref={containerRef}
        columns={[1, null, 2]}
        fontWeight={600}
        gap={12}
      >
        <VStack align={"stretch"} id="servicesContent">
          <VStack className={"sectionNavLabel"} mb={2} align={"stretch"}>
            <SectionNav link={landingData.landingNav.id[1].link}>
              {landingData.services[lang].nav}
            </SectionNav>
          </VStack>

          <Text
            fontSize={[32, 42, 52]}
            lineHeight={1.3}
            fontWeight={600}
            mb={4}
            className="sectionTitle"
            textAlign={["center", null, "left"]}
          >
            {data.tagline}
          </Text>
          <Text
            opacity={0.6}
            className="serviceItem"
            textAlign={["center", null, "left"]}
            mb={8}
          >
            {data.narrative}
          </Text>

          <HStack mt={"auto"} gap={8} w={"100%"}>
            {/* <Image
              src="/vectors/precisionAndQuality.svg"
              className="serviceItem"
            /> */}
            <Lottie
              className="serviceItem"
              animationData={precisionAndQualityLottie}
              loop={true}
            />
          </HStack>
        </VStack>

        <VStack align={"stretch"} pt={12} gap={0}>
          <Heading
            as={"h1"}
            className="sectionTitle display"
            fontWeight={600}
            fontSize={"28px !important"}
            textAlign={["center", null, "left"]}
            mb={4}
          >
            {landingData.services[lang].title}
          </Heading>

          {data.items.map((service: any, i: number) => (
            <VStack key={i} className="serviceItem" gap={0} flex={1}>
              <VStack flex={1} key={i}>
                <HStack
                  flex={1}
                  cursor={"pointer"}
                  as={Link}
                  to={service.detailLink}
                  py={5}
                  className="clicky"
                  transition={"200ms"}
                  role="group"
                >
                  <VStack align={"stretch"}>
                    <HStack>
                      <Icon
                        as={service.icon}
                        fontSize={24}
                        // opacity={0.3}
                        color={"p.500"}
                      />
                      <Text fontSize={24} fontWeight={600}>
                        {service.title}
                      </Text>
                    </HStack>

                    <Text opacity={0.6} noOfLines={2}>
                      {service.narrative}
                    </Text>
                  </VStack>

                  <Icon
                    as={RiArrowRightSLine}
                    fontSize={32}
                    opacity={0.6}
                    transition={"200ms"}
                    _groupHover={{
                      color: "var(--p500)",
                      transform: "scale(1.2) translateX(6px)",
                      opacity: 1,
                    }}
                  />
                </HStack>
              </VStack>

              {i < 2 && (
                <Box
                  w={"100%"}
                  h={"1px"}
                  bg={"var(--divider3)"}
                  flexShrink={0}
                />
              )}
            </VStack>
          ))}
        </VStack>
      </SimpleGrid>
    </Container>
  );
}
