import { AboutData } from "./types";

const aboutData: AboutData = {
  id: {
    intro: {
      title: "Siapa Kami?",
      narrative: [
        "Kami adalah Software House yang berkomitmen untuk menyediakan solusi digital terbaik bagi klien kami. Dengan menggabungkan keahlian dan dedikasi, kami membentuk tim yang terdiri dari desainer UI/UX, pengembang React frontend, dan pengembang Laravel backend.",
        "Kami mengerti bahwa setiap proyek memiliki kebutuhan yang unik, itulah sebabnya kami menawarkan layanan yang sangat fleksibel dalam pengerjaan proyek.",
        "Kami bangga menghasilkan karya berkualitas tinggi dengan teknologi terkini, memastikan setiap produk memenuhi standar dan memberikan nilai tambah bagi bisnis dan pengguna.",
        "Kolaborasi kuat dengan klien adalah kunci sukses kami. Kami memahami visi dan tujuan klien secara mendalam serta berkomunikasi terbuka sepanjang proyek.",
        "Dengan pengalaman dan kompetensi kami yang luas, bersama dengan komitmen kami untuk memberikan layanan terbaik, Distro Studio siap menjadi mitra digital yang dapat diandalkan untuk memenuhi semua kebutuhan teknologi informasi Anda.",
      ],
    },
    members: {
      title: "Tim Ahli Kami",
    },
  },
  en: {
    intro: {
      title: "Who We Are?",
      narrative: [
        "We are a software house committed to providing the best digital solutions for our clients. By combining expertise and dedication, we assemble a team consisting of UI/UX designers, frontend React developers, and backend Laravel developers.",
        "We understand that every project has unique requirements, which is why we offer highly flexible services in project execution. With an approach focused on client needs and objectives, we strive to deliver solutions that meet and exceed their expectations.",
        "We pride ourselves on producing high quality work with the latest technology, ensuring every product meets standards and provides added value to businesses and users.",
        "Strong collaboration with clients is the key to our success. We understand the client's vision and goals deeply and communicate openly throughout the project.",
        "With our extensive experience and expertise, coupled with our commitment to providing the best services, Distro Studio is ready to be a reliable digital partner to fulfill all your information technology needs.",
      ],
    },
    members: {
      title: "Our Expert Team",
    },
  },
  techstack: [
    {
      name: "HTML",
      logo: "/images/about/techstack/html.png",
    },
    {
      name: "CSS",
      logo: "/images/about/techstack/css.png",
    },
    {
      name: "React",
      logo: "/images/about/techstack/react.png",
    },
    {
      name: "Javascript",
      logo: "/images/about/techstack/js.png",
    },
    {
      name: "Typescript",
      logo: "/images/about/techstack/ts.png",
    },
    {
      name: "ChakraUI",
      logo: "/images/about/techstack/chakraui.png",
    },
    {
      name: "Zustand",
      logo: "/images/about/techstack/zustand.png",
    },
    {
      name: "Axios",
      logo: "/images/about/techstack/axios.png",
    },
    {
      name: "GSAP",
      logo: "/images/about/techstack/gsap.png",
    },
    {
      name: "Laravel",
      logo: "/images/about/techstack/laravel.png",
    },
    {
      name: "PHP",
      logo: "/images/about/techstack/php.png",
    },
    {
      name: "MySQL",
      logo: "/images/about/techstack/mysql.png",
    },
    {
      name: "Postman",
      logo: "/images/about/techstack/postman.png",
    },
    {
      name: "Figma",
      logo: "/images/about/techstack/figma.png",
    },
    {
      name: "CorelDRAW",
      logo: "/images/about/techstack/corel.png",
    },
  ],
};

export default aboutData;
