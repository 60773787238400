import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useGSAP } from "@gsap/react";
import { RiCloseLine, RiSearchLine } from "@remixicon/react";
import gsap from "gsap";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PortfolioCard from "../components/Cards/PortfolioCard";
import Container from "../components/Container";
import NoData from "../components/Feedbacks/NoData";
import PageHeader from "../components/PageHeader";
import TopNav from "../components/TopNav";
import landingData from "../constant/landingData";
import servicesData from "../constant/servicesData";
import { WorkData } from "../constant/types";
import worksData from "../constant/worksData";
import useTrigger from "../global/useTrigger";
import Contact from "../landingSections/Contact";
import Footer from "../landingSections/Footer";
import { getLang } from "../lib/lang";
import useScreenWidth from "../hooks/useScreenWidth";

export default function WorksPage() {
  const { trigger } = useTrigger();
  const lang = getLang();
  const categories = [
    lang === "id" ? "Semua Kategori" : "All Category",
    servicesData.id.items[0].title,
    servicesData.id.items[1].title,
    servicesData.id.items[2].title,
  ];

  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const category = query.get("category");
  const search = query.get("search");

  const handleOnClick = (clickedElement: HTMLElement) => {
    const container = document.getElementById("categoryChipContainer");

    if (clickedElement && container) {
      container.style.scrollSnapType = "none";

      clickedElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });

      setTimeout(() => {
        container.style.scrollSnapType = "x mandatory";
      }, 500);
    }
  };

  const activeRef = useRef<any>(null);

  const SearchInputRef = useRef<HTMLInputElement>(null);
  const [searchTimeout, setSearchTimeout] = useState<any>(null);
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        query.set("search", e.target.value);
        navigate(`${location.pathname}?${query.toString()}`);
      }, 500)
    );
  };
  const [data, setData] = useState<WorkData[] | null>(null);
  useMemo(() => {
    const filteredData = worksData[lang].filter((work) => {
      const searchTerm = search?.toLowerCase() as string;
      const categoryTerm = parseInt(category as string);
      // console.log(searchTerm);

      const ok =
        (work.title.toLowerCase().includes(searchTerm) ||
          work.subTitle.toLowerCase().includes(searchTerm) ||
          work.clientName.toLowerCase().includes(searchTerm) ||
          work.narrative.toLowerCase().includes(searchTerm) ||
          work.solution.toLowerCase().includes(searchTerm) ||
          searchTerm === undefined) &&
        (work.category + 1 === categoryTerm || categoryTerm === 0);

      return ok;
    });

    setData(filteredData);
  }, [category, lang, search]);

  const onReset = () => {
    query.set("search", "");
    query.set("category", "0");
    navigate(`${location.pathname}?${query.toString()}`);
    if (SearchInputRef.current) {
      SearchInputRef.current.value = "";
    }
  };

  const containerRef = useRef(null);

  useEffect(() => {
    workItemRefs.current = workItemRefs.current.slice(0, data?.length);
  }, [data]);

  useGSAP(() => {
    gsap.from("#worksControl", {
      y: -50,
      opacity: 0,
    });
    gsap.from(".categoryChip", {
      opacity: 0,
      scale: 0.5,
      stagger: {
        each: 0.2,
      },
    });
  });

  useGSAP(
    () => {
      if (workItemRefs.current.length) {
        gsap.from(workItemRefs.current, {
          scale: 0.5,
          opacity: 0,
          stagger: {
            each: 0.05,
          },
        });
      }
    },
    {
      scope: containerRef,
      dependencies: [data],
      revertOnUpdate: true,
    }
  );

  const workItemRefs = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    workItemRefs.current = workItemRefs.current.slice(0, data?.length);
  }, [data]);

  const sw = useScreenWidth();

  return (
    <VStack
      ref={containerRef}
      id="worksPage"
      gap={0}
      align={"stretch"}
      overflowX={"clip"}
    >
      <TopNav />

      <Text display={"none"}>{trigger}</Text>

      <PageHeader>{landingData.works.content[lang].nav}</PageHeader>

      <Container>
        <HStack
          id={"worksControl"}
          w={"100%"}
          maxW={"700px"}
          justify={"center"}
          mx={"auto"}
          mb={10}
        >
          <InputGroup>
            <InputLeftElement pointerEvents="none" px={6}>
              <Icon as={RiSearchLine} fontSize={20} />
            </InputLeftElement>
            <Input
              ref={SearchInputRef}
              placeholder={lang === "id" ? "Pencarian" : "Search"}
              className="input"
              onChange={handleSearch}
              borderRadius={"full !important"}
              pl={12}
            />
          </InputGroup>

          <Tooltip label={"Reset"} openDelay={500}>
            <IconButton
              onClick={onReset}
              className="btn-solid clicky"
              aria-label="reset filter button"
              icon={<Icon as={RiCloseLine} fontSize={20} />}
            />
          </Tooltip>
        </HStack>
      </Container>

      <Box overflowX={"auto"} className="noScroll">
        <HStack
          id="categoryChipContainer"
          justify={"center"}
          w={"max-content"}
          mx={"auto"}
          px={[5, 6, 8]}
        >
          {categories.map((c, i) => (
            <Box
              className="categoryChip"
              key={i}
              ref={parseInt(category as string) === i ? activeRef : null}
            >
              <Button
                className="btn-solid clicky"
                color={parseInt(category as string) === i ? "p.500" : ""}
                onClick={(e) => {
                  handleOnClick(e.currentTarget);
                  query.set("category", i.toString());
                  navigate(`${location.pathname}?${query.toString()}`);
                }}
              >
                {c}
              </Button>
            </Box>
          ))}
        </HStack>
      </Box>

      <Container py={16} pt={10} flex={1} minH={"500px"}>
        {data && data.length > 0 ? (
          <SimpleGrid
            columns={sw < 345 ? 1 : [2, 2, 3, null, 4]}
            gap={5}
            zIndex={1}
          >
            {data
              .slice()
              .reverse()
              .map((work, i) => {
                return (
                  <Box
                    className={"workItem"}
                    ref={(el) => {
                      if (el) {
                        workItemRefs.current[i] = el;
                      }
                    }}
                    key={i}
                  >
                    <PortfolioCard
                      work={work}
                      index={i}
                      aspectRatio={10 / 16}
                    />
                  </Box>
                );
              })}
          </SimpleGrid>
        ) : (
          <NoData />
        )}
      </Container>

      <Contact />

      <Footer />
    </VStack>
  );
}
