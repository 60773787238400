import { StackProps, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { WorkData } from "../../constant/types";

interface Props extends StackProps {
  work: WorkData;
  index: number;
  setActiveWork?: any;
}

export default function PortfolioCard({
  work,
  index,
  setActiveWork,
  ...props
}: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleOnClick = () => {
    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }
    if (setActiveWork) {
      setActiveWork(work);
    }
  };

  const location = useLocation();
  const path = location.pathname;

  return (
    <VStack
      bgImage={work.image}
      bgSize={"cover"}
      bgPos={"bottom"}
      cursor={"pointer"}
      scrollSnapAlign={"center"}
      align={"stretch"}
      borderRadius={12}
      overflow={"clip"}
      className="clicky"
      _hover={{ opacity: isOpen ? 1 : 0.8 }}
      transition={"200ms"}
      gap={0}
      onClick={handleOnClick}
      aspectRatio={"10/16"}
      as={Link}
      to={`/works/${work.id}`}
      {...props}
    >
      <VStack
        p={path === "/works" ? [4, null, null, 8] : [6, null, 8]}
        align={"stretch"}
      >
        <Text
          color={"white"}
          fontSize={path === "/works" ? [16, null, null, 24] : 24}
          fontWeight={650}
          noOfLines={2}
          lineHeight={1.2}
          mb={2}
        >
          {work.title}
        </Text>
        <Text
          fontSize={path === "/works" ? [12, null, null, 16] : 16}
          color={"white"}
          opacity={0.6}
          noOfLines={2}
        >
          {work.subTitle}
        </Text>
      </VStack>

      {/* <VStack
        transition={"200ms"}
        align={"stretch"}
        justify={"center"}
        p={6}
        h={"100%"}
        bg={"blackAlpha.800"}
        opacity={isOpen ? 1 : 0}
      >
        <VStack
          flex={1}
          transform={isOpen ? "translateY(0)" : "translateY(200px)"}
          transition={"200ms"}
        >
          <Text
            my={"auto"}
            textAlign={"center"}
            fontSize={20}
            fontWeight={600}
            color={"white"}
            noOfLines={3}
          >
            {work.narrative}
          </Text>

          <Button
            w={"100%"}
            mt={"auto"}
            as={Link}
            to={`/works/${props.index}`}
            className="btn"
            color={"p.500"}
            rightIcon={<Icon as={ArrowUpRight} fontSize={iconSize} />}
          >
            Detail
          </Button>
        </VStack>
      </VStack> */}
    </VStack>
  );
}
