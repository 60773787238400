import {
  Box,
  Heading,
  ListItem,
  OrderedList,
  Text,
  VStack,
} from "@chakra-ui/react";
import TopNav from "../components/TopNav";
import PageHeader from "../components/PageHeader";
import Container from "../components/Container";
import landingData from "../constant/landingData";
import { getLang } from "../lib/lang";
import useTrigger from "../global/useTrigger";
import policyData from "../constant/policyData";
import Footer from "../landingSections/Footer";
import Contact from "../landingSections/Contact";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

export default function Policy() {
  useTrigger();
  const lang = getLang();

  //Animation
  useGSAP(() => {
    gsap.from(".policyItem", {
      y: 50,
      opacity: 0,
      stagger: {
        each: 0.2,
      },
    });
  });

  return (
    <VStack gap={0} align={"stretch"} overflowX={"clip"}>
      <TopNav />

      <PageHeader>
        {landingData.contact[lang].form.termsAndConditions}
      </PageHeader>

      <VStack gap={0} align={"stretch"} pb={20}>
        <Container>
          <OrderedList>
            {policyData[lang].map((policy, i) => (
              <ListItem
                key={i}
                className="policyItem"
                fontSize={[22, null, 24]}
                fontWeight={600}
                mb={8}
              >
                <Box>
                  <Heading fontSize={[22, null, 24]} mb={2}>
                    {policy.title}
                  </Heading>

                  <OrderedList
                    styleType={"lower-alpha"}
                    fontSize={[16, null, 18]}
                    fontWeight={500}
                    opacity={0.6}
                  >
                    {policy.items.map((item, j) => (
                      <ListItem key={j} mb={2}>
                        {item}
                      </ListItem>
                    ))}
                  </OrderedList>
                </Box>
              </ListItem>
            ))}
          </OrderedList>

          <Text fontSize={[16, null, 18]} opacity={0.6} mb={8}>
            {lang === "id"
              ? "Dengan menandatangani atau menyetujui syarat dan ketentuan ini, kedua belah pihak dianggap telah memahami dan setuju dengan semua persyaratan yang tercantum di atas."
              : ""}
          </Text>
        </Container>
      </VStack>

      <Contact />

      <Footer />
    </VStack>
  );
}
