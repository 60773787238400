import {
  Box,
  Button,
  HStack,
  Heading,
  Icon,
  IconButton,
  Image,
  Text,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import { useGSAP } from "@gsap/react";
import { RiArrowLeftSLine, RiArrowRightSLine } from "@remixicon/react";
import gsap from "gsap";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Container from "../components/Container";
import ImageView from "../components/ImageView";
import TopNav from "../components/TopNav";
import { iconSize } from "../constant/sizes";
import worksData from "../constant/worksData";
import useTrigger from "../global/useTrigger";
import Contact from "../landingSections/Contact";
import Footer from "../landingSections/Footer";
import { getLang } from "../lib/lang";
import scrollTriggerConfig from "../lib/scrollTriggerConfig";
import MissingPage from "./MissingPage";

const WorksDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const worksId = parseInt(id as string);
  const lang = getLang();
  const { trigger } = useTrigger();
  const data = worksData[lang].slice()[worksId];
  const [currentImage, setCurrentImage] = useState<string | null>(null); // State untuk gambar thumbnail

  const handlePrevSs = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= data.ssScrollLength;
    }
  };

  const handleNextSs = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += data.ssScrollLength;
    }
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const carouselRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= 999999;
    }
  }, [id]);

  const navigate = useNavigate();

  const containerWorkDetailRef = useRef(null);

  // Gunakan useEffect untuk mengatur ulang gambar saat ID berubah
  useEffect(() => {
    setCurrentImage(null); // Setel gambar ke null atau placeholder ketika ID berubah
  }, [id]);

  useGSAP(
    () => {
      gsap.from(".initWorksDetail", {
        y: 50,
        opacity: 0,
        stagger: {
          each: 0.2,
        },
      });

      gsap.from(".narrative", {
        scrollTrigger: scrollTriggerConfig(".narrativeContent"),
        y: 50,
        opacity: 0,
        stagger: {
          each: 0.2,
        },
      });

      gsap.from(".carouselControl", {
        scrollTrigger: scrollTriggerConfig("#screenshots"),
        scale: 0.5,
        opacity: 0,
      });

      gsap.from(".image", {
        scrollTrigger: scrollTriggerConfig("#screenshots"),
        scale: 0.5,
        opacity: 0,
        stagger: {
          each: 0.05,
        },
      });
    },
    { scope: containerWorkDetailRef, dependencies: [worksId] }
  );

  if (!isNaN(worksId) && data) {
    return (
      <VStack
        ref={containerWorkDetailRef}
        id="workDetailPage"
        gap={0}
        align={"stretch"}
        overflowX={"clip"}
      >
        <Text display={"none"}>{trigger}</Text>

        <TopNav />

        <Container>
          <VStack
            mt={24}
            mb={32}
            minH={"calc(100vh - 650px)"}
            justify={"center"}
          >
            {(data.clientName || data.clientLogo) && (
              <HStack
                className="initWorksDetail"
                mb={4}
                gap={3}
                justify={"center"}
              >
                {data.clientLogo && (
                  <Image
                    alt={`Logo ${data.clientName}`}
                    loading={"lazy"}
                    src={data.clientLogo}
                    h={"20px"}
                    mb={"2px"}
                    borderRadius={"0 !important"}
                  />
                )}
                {data.clientName && (
                  <Text
                    fontWeight={700}
                    opacity={0.5}
                    fontSize={18}
                    // textAlign={"center"}
                  >
                    {data.clientName}
                  </Text>
                )}
              </HStack>
            )}

            <Heading
              className="initWorksDetail"
              as={"h1"}
              textAlign={"center"}
              fontWeight={600}
              maxW={"820px"}
            >
              {data.subTitle}
            </Heading>
          </VStack>

          {/* Gunakan state currentImage untuk menampilkan thumbnail */}
          <Image
            alt={`Thumbnail ${data.title} from Distro Studio`}
            className="initWorksDetail"
            loading={"lazy"}
            src={currentImage || data.imageAlter} // Gunakan currentImage atau fallback ke data.imageAlter
            borderRadius={"12px !important"}
            mb={20}
            aspectRatio={3 / 2}
            w={"100%"}
            objectFit={"cover"}
            onLoad={() => setCurrentImage(data.imageAlter)} // Setel currentImage setelah gambar selesai dimuat
          />

          <VStack className="narrativeContent" align={"stretch"}>
            <Wrap mb={20}>
              <Box
                className="narrative"
                flex={["0 0 100%", null, null, "0 0 200px"]}
              >
                <Text fontWeight={600} fontSize={28} lineHeight={1.2}>
                  {lang === "id" ? "Brief Proyek" : "Project Brief"}
                </Text>
              </Box>

              <Box className="narrative" flex={["0 0 100%", null, "1 0 600px"]}>
                <Text fontSize={[16, null, 18]}>{data.narrative}</Text>
              </Box>
            </Wrap>

            <Wrap mb={20}>
              <Box
                className="narrative"
                flex={["0 0 100%", null, null, "0 0 200px"]}
              >
                <Text fontWeight={600} fontSize={28} lineHeight={1.2}>
                  {lang === "id" ? "Solusi Kami" : "Our Solution"}
                </Text>
              </Box>

              <Box className="narrative" flex={["0 0 100%", null, "1 0 600px"]}>
                <Text fontSize={[16, null, 18]}>{data.solution}</Text>
              </Box>
            </Wrap>
          </VStack>
        </Container>

        <Box
          id="screenshots"
          w={"100%"}
          mb={8}
          bg={"var(--divider)"}
          pt={6}
          pb={6}
        >
          <VStack
            className="noScroll"
            overflowX={"auto"}
            ref={containerRef}
            scrollBehavior={"smooth"}
            align={"flex-start"}
            scrollSnapType={"x mandatory"}
          >
            <HStack
              ref={carouselRef}
              minW={"100%"}
              w={"max-content"}
              justify={"center"}
              gap={4}
              px={[4, 6, 8]}
              py={2}
            >
              {data.ss.map((imagePath, i) => (
                <Box
                  className="image"
                  key={i}
                  p={4}
                  borderRadius={12}
                  // border={"1px solid var(--divider3)"}
                >
                  <ImageView data={data} imagePath={imagePath} index={i} />
                </Box>
              ))}
            </HStack>
          </VStack>

          <Container className="carouselControl" mt={2}>
            <HStack mx={"auto"}>
              <IconButton
                onClick={handlePrevSs}
                className="btn clicky"
                aria-label="carousel-previous"
                icon={<Icon as={RiArrowLeftSLine} fontSize={20} />}
              />
              <IconButton
                onClick={handleNextSs}
                className="btn clicky"
                aria-label="carousel-next"
                icon={<Icon as={RiArrowRightSLine} fontSize={20} />}
              />
            </HStack>
          </Container>
        </Box>

        <Container>
          <VStack
            p={4}
            pt={8}
            borderRadius={12}
            // bg={"var(--divider)"}
            mb={[5, 6, 8]}
          >
            <Text
              textAlign={"center"}
              mb={4}
              fontSize={[16, null, 18]}
              opacity={0.6}
            >
              {lang === "id"
                ? "Telusuri karya-karya luar biasa kami yang lain, anda bisa navigasi langsung melalui tombol di bawah"
                : "Browse our other amazing works, you can navigate directly through the button below"}
            </Text>

            <HStack mx={"auto"} justify={"space-between"} w={"100%"}>
              <Button
                pl={4}
                leftIcon={<Icon as={RiArrowLeftSLine} fontSize={iconSize} />}
                className="btn"
                onClick={() => {
                  if (worksId - 1 >= 0) {
                    navigate(`/works/${worksId - 1}`);
                  }
                }}
                isDisabled={worksId === 0}
              >
                <Text noOfLines={1} w={"fit-content"}>
                  {lang === "id" ? "Sebelumnya" : "Previous"}
                </Text>
              </Button>

              <Button
                pr={4}
                rightIcon={<Icon as={RiArrowRightSLine} fontSize={iconSize} />}
                className="btn"
                onClick={() => {
                  const maxLength = worksData.id.length;
                  if (worksId + 1 < maxLength) {
                    navigate(`/works/${worksId + 1}`);
                  }
                }}
                isDisabled={worksData.id.length === worksId + 1}
              >
                <Text noOfLines={1} w={"fit-content"}>
                  {lang === "id" ? "Selanjutnya" : "Next"}
                </Text>
              </Button>
            </HStack>
          </VStack>
        </Container>

        <Contact />

        <Footer />
      </VStack>
    );
  } else {
    return <MissingPage />;
  }
};

export default WorksDetailPage;
