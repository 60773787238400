import {
  // Link as ChakraLink,
  Box,
  Heading,
  HStack,
  Icon,
  IconButton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import SectionNav from "../components/SectionNav";
import landingData from "../constant/landingData";

import { useGSAP } from "@gsap/react";
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiArrowRightUpLine,
} from "@remixicon/react";
import gsap from "gsap";
import { Link } from "react-router-dom";
import PortfolioCard from "../components/Cards/PortfolioCard";
import Container from "../components/Container";
import useScreenWidth from "../hooks/useScreenWidth";
import { getLang } from "../lib/lang";
import scrollTriggerConfig from "../lib/scrollTriggerConfig";

export default function Works() {
  const lang = getLang();
  const sw = useScreenWidth();
  const containerRef = useRef<HTMLDivElement>(null);
  const carouselRef = useRef<HTMLDivElement>(null);
  const [lastClickedCard, setLastClickedCard] = useState<number | null>(null);

  const handlePrev = (): void => {
    if (lastClickedCard && lastClickedCard > 1) {
      setLastClickedCard(lastClickedCard - 1);
    }
    if (containerRef.current) {
      containerRef.current.scrollLeft += -200;
    }
  };

  const handleNext = (): void => {
    if (lastClickedCard && lastClickedCard < 6) {
      setLastClickedCard(lastClickedCard + 1);
    }
    if (containerRef.current) {
      containerRef.current.scrollLeft += 200;
    }
  };

  const containerWorksRef = useRef(null);

  // Animation
  useGSAP(
    () => {
      gsap.from([".sectionNavLabel", ".sectionTitle"], {
        scrollTrigger: scrollTriggerConfig("#worksContent"),
        y: 50,
        opacity: 0,
        stagger: {
          each: 0.2,
        },
      });

      gsap.from("#carouselControl", {
        scrollTrigger: scrollTriggerConfig("#worksContent"),
        scale: 0.5,
        opacity: 0,
        delay: 0.5,
      });

      gsap.from([`.workItem`, "#moreWorks"], {
        scrollTrigger: scrollTriggerConfig("#worksContent"),
        scale: 0.5,
        opacity: 0,
        stagger: {
          each: 0.05,
        },
      });
    },
    { scope: containerWorksRef }
  );

  return (
    <VStack ref={containerWorksRef} id="works" gap={0} position={"relative"}>
      <VStack
        py={20}
        borderRadius={"0 0 24px 24px"}
        overflow="hidden"
        w={"100%"}
        align={"stretch"}
        gap={0}
        id={"worksContent"}
      >
        <Container>
          <VStack mb={6} className="sectionHeader" align={"stretch"}>
            <Box className={"sectionNavLabel"}>
              <SectionNav link={landingData.landingNav.id[2].link}>
                {landingData.works.content[lang].nav}
              </SectionNav>
            </Box>
            <Heading
              as={"h1"}
              className="sectionTitle"
              textAlign={["center", null, "left"]}
            >
              {landingData.works.content[lang].title}
            </Heading>
          </VStack>
        </Container>

        {/* Carousel */}
        <Box w={"100%"} bottom={[10, null, 20]} mx={"auto"}>
          <VStack
            id="landingWorksContainer"
            w={"100%"}
            className="noScroll"
            overflow={"auto"}
            ref={containerRef}
            align={"flex-start"}
            scrollBehavior={"smooth"}
            scrollSnapType={"x mandatory"}
          >
            <HStack
              ref={carouselRef}
              w={"max-content"}
              gap={5}
              mx={"auto"}
              px={
                sw < 1440
                  ? [5, null, 8]
                  : `calc((100vw - 1440px + ${sw < 768 ? "30px" : "48px"})/2)`
              }
              py={4}
              align={"stretch"}
            >
              {landingData.works.worksItem[lang]
                .slice()
                .reverse()
                .map((work, i) => {
                  const ok = i < 6;

                  return (
                    ok && (
                      <Box className="workItem" key={i}>
                        <PortfolioCard
                          work={work}
                          index={i}
                          w={sw < 350 ? "calc(100vw - 40px)" : "300px"}
                        />
                      </Box>
                    )
                  );
                })}

              <Box id="moreWorks">
                <VStack
                  scrollSnapAlign={"center"}
                  align={"center"}
                  bg={"var(--divider)"}
                  borderRadius={12}
                  overflow={"clip"}
                  h={"100%"}
                  w={"300px"}
                  aspectRatio={"10/16"}
                  p={5}
                  transition={"200ms"}
                  justify={"center"}
                  role="group"
                  gap={0}
                  cursor={"pointer"}
                  _hover={{ bg: "var(--divider3)" }}
                  as={Link}
                  to={"/works?search=&category=0"}
                  position={"relative"}
                  className="clicky"
                >
                  <Icon
                    as={RiArrowRightUpLine}
                    fontSize={600}
                    // h={"0 !important"}
                    _groupHover={{ transform: "scale(1.2)" }}
                    transition={"200ms"}
                    mb={4}
                    position={"absolute"}
                    bottom={"-100px"}
                    right={"-100px"}
                    zIndex={1}
                    opacity={0.05}
                  />
                  <Text textAlign={"center"} fontSize={24} fontWeight={600}>
                    {lang === "id" ? "Lihat Karya Lainnya" : "View Other Works"}
                  </Text>
                </VStack>
              </Box>
            </HStack>
          </VStack>

          {/* carousel control */}
          <Container id="carouselControl" mt={4}>
            <HStack ml={"auto"}>
              <IconButton
                onClick={handlePrev}
                aria-label="Prev"
                icon={<Icon as={RiArrowLeftSLine} fontSize={24} />}
                className="btn-solid clikcy"
                // color={"white"}
              />

              <IconButton
                onClick={handleNext}
                aria-label="Prev"
                icon={<Icon as={RiArrowRightSLine} fontSize={24} />}
                className="btn-solid clikcy"
                // color={"white"}
              />
            </HStack>
          </Container>
        </Box>
      </VStack>
    </VStack>
  );
}
