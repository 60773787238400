import { StackProps, VStack } from "@chakra-ui/react";

interface Props extends StackProps {
  children?: any;
}

export default function PageContainer({ children, ...props }: Props) {
  return (
    <VStack
      gap={0}
      minH={"100vh"}
      w={"100%"}
      align={"stretch"}
      overflowX={"clip"}
      {...props}
    >
      {children}
    </VStack>
  );
}
