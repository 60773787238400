import { Button, HStack, Icon, Text, VStack } from "@chakra-ui/react";
import { useGSAP } from "@gsap/react";
import { RiArrowRightLine } from "@remixicon/react";
import gsap from "gsap";
import { Link } from "react-router-dom";
import Container from "../components/Container";
import landingData from "../constant/landingData";
import { getLang } from "../lib/lang";
import scrollTriggerConfig from "../lib/scrollTriggerConfig";
import { useRef } from "react";

export default function Contact() {
  const lang = getLang();
  const containerRef = useRef(null);

  // Animation
  useGSAP(
    () => {
      gsap.from(".contactContent", {
        scrollTrigger: scrollTriggerConfig("#contactContent"),
        y: 50,
        opacity: 0,
        stagger: {
          each: 0.1,
        },
      });
    },
    { scope: containerRef }
  );

  return (
    <VStack
      ref={containerRef}
      id="contact"
      align={"stretch"}
      py={20}
      px={8}
      // bg={"var(--darka)"}
      bg={"p.500"}
      color={"white"}
      borderTop={"1px solid var(--divider3)"}
      position={"relative"}
    >
      <Container px={0} id="contactContent" position={"relative"}>
        <Text
          // fontSize={[32, 42]}
          fontWeight={500}
          maxW={"650px"}
          mb={8}
          lineHeight={1.4}
          textAlign={"center"}
          mx={"auto"}
          className="contactContent sectionTitle"
        >
          {lang === "id"
            ? "Mulai Angkat Idemu Menjadi Produk Digital yang Brilian"
            : "Start Turning Your Ideas into Brilliant Digital Products"}
        </Text>

        <Text
          mx={"auto"}
          textAlign={"center"}
          maxW={"600px"}
          opacity={0.6}
          mb={10}
          className="contactContent"
        >
          {lang === "id"
            ? "Percayakan pada kualitas karya Distro Studio"
            : "Trust the quality of Distro Studio's work"}
        </Text>

        <VStack className="contactContent">
          <Button
            flexShrink={0}
            className="clicky"
            pl={8}
            pr={7}
            h={"50px"}
            w={["100%", "fit-content"]}
            as={Link}
            to={"/contact"}
            bg={"white"}
            _hover={{ bg: "whiteAlpha.800" }}
            _active={{ bg: "whiteAlpha.700" }}
            mx={"auto"}
          >
            <HStack
              justify={["center", null, "space-between"]}
              w={"100%"}
              color={"p.500"}
            >
              <Text pr={2} fontSize={18}>
                {landingData.hero[lang].letsTalk.label}
              </Text>
              <Icon as={RiArrowRightLine} fontSize={24} />
            </HStack>
          </Button>
        </VStack>
      </Container>
    </VStack>
  );
}
