import { useEffect } from "react";
import PageContainer from "../components/PageContainer";
import TopNav from "../components/TopNav";
import useTrigger from "../global/useTrigger";
import Hero from "../landingSections/Hero";
import Services from "../landingSections/Services";
import { getLang, setLang as setLangLocal } from "../lib/lang";
import Works from "../landingSections/Works";
import About from "../landingSections/About";
import Quality from "../landingSections/Quality";
import OurClients from "../landingSections/OurClients";
import Contact from "../landingSections/Contact";
import Footer from "../landingSections/Footer";

export default function Landing() {
  const { setTrigger } = useTrigger();
  const lang = getLang();

  useEffect(() => {
    if (!lang) {
      setLangLocal("id");
      setTrigger(true);
    } else {
      setLangLocal(lang);
      setTrigger(true);
    }
  }, [setTrigger]);

  return (
    lang && (
      <PageContainer>
        <TopNav />

        <Hero />

        <Services />

        <Works />

        <Quality />

        <About />

        <OurClients />

        <Contact />

        <Footer />
      </PageContainer>
    )
  );
}
