import {
  Icon,
  Text,
  useDisclosure,
  VStack,
  Link as ChakraLink,
  Button,
  Image,
} from "@chakra-ui/react";
import { iconSize } from "../../constant/sizes";
import { getLang } from "../../lib/lang";
import { RiDoubleQuotesR } from "@remixicon/react";

export default function MemberCard(props: any) {
  const data = props.data;
  const lang = getLang();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleOnClick = () => {
    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }
  };

  return (
    <VStack flex={1} gap={0} align={"stretch"} w={"100%"}>
      <VStack
        bg={"var(--divider)"}
        cursor={"pointer"}
        scrollSnapAlign={"center"}
        align={"stretch"}
        borderRadius={12}
        overflow={"clip"}
        className="clicky"
        w={props?.w || "100%"}
        _hover={{ opacity: isOpen ? 1 : 0.8 }}
        transition={"200ms"}
        gap={0}
        onClick={handleOnClick}
        aspectRatio={"3/4"}
        position={"relative"}
      >
        <Image
          src={data.image}
          alt={data.name}
          position={"absolute"}
          top={0}
          left={0}
          h={"100%"}
        />

        <VStack
          zIndex={2}
          transition={"200ms"}
          align={"stretch"}
          justify={"space-between"}
          p={4}
          h={"100%"}
          bg={"blackAlpha.800"}
          opacity={isOpen ? 1 : 0}
          flex={1}
          overflowY={isOpen ? "auto" : "clip"}
          className="noScroll"
        >
          <VStack
            opacity={0.5}
            color={"white"}
            transform={isOpen ? "translateY(0)" : "translateY(-400px)"}
            transition={"200ms"}
          >
            <Icon as={RiDoubleQuotesR} />
            <Text textAlign={"center"} fontSize={13}>
              {data.quote[lang]}
            </Text>
          </VStack>
          <VStack
            transform={isOpen ? "translateY(0)" : "translateY(400px)"}
            transition={"200ms"}
            align={"stretch"}
            gap={0}
          >
            {data.sosmeds.map(
              (
                sosmed: { label: string; icon: any; link: string },
                i: number
              ) => {
                return (
                  sosmed.link && (
                    <Button
                      color="p.500"
                      key={i}
                      className="btn"
                      leftIcon={<Icon as={sosmed.icon} fontSize={iconSize} />}
                      as={ChakraLink}
                      href={sosmed.link}
                      isExternal
                      size={"sm"}
                    >
                      {sosmed.label}
                    </Button>
                  )
                );
              }
            )}
          </VStack>
        </VStack>
      </VStack>

      <VStack flex={1} gap={0} align={"stretch"}>
        <Text fontWeight={600} mt={4} lineHeight={1.2} mb={"2px"}>
          {data.name}
        </Text>
        <Text opacity={0.5}>#{data.role}</Text>
      </VStack>
    </VStack>
  );
}
