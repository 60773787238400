import { HStack, StackProps, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

interface Props extends StackProps {
  link?: string;
}

export default function SectionNav({ link, ...props }: Props) {
  return (
    <HStack
      bg={"var(--p500a4)"}
      borderRadius={"full"}
      px={4}
      py={"2px"}
      as={Link}
      to={link || ""}
      w={"fit-content"}
      mx={["auto", null, 0]}
      {...props}
    >
      {/* <Box h={"1px"} w={"20px"} bg={"current"} />
      <Text as={"h1"}>{props.children}</Text>;
      <Box h={"1px"} w={"20px"} bg={"current"} /> */}
      <Text as={"h1"} fontSize={[12, null, 14]} color={"p.500"}>
        {props.children}
      </Text>
      ;
    </HStack>
  );
}
