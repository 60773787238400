import { StackProps, VStack } from "@chakra-ui/react";

interface Props extends StackProps {
  children?: any;
}

export default function Container({ children, ...props }: Props) {
  return (
    <VStack
      className="container"
      gap={0}
      px={[5, null, 8]}
      w={"100%"}
      maxW={"1440px"}
      mx={"auto"}
      align={"stretch"}
      {...props}
    >
      {children}
    </VStack>
  );
}
