import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Link as ChakraLink,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Text,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import { useGSAP } from "@gsap/react";
import { RiArrowRightUpLine } from "@remixicon/react";
import gsap from "gsap";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import ContactForm from "../components/ContactForm";
import Container from "../components/Container";
import PageHeader from "../components/PageHeader";
import TopNav from "../components/TopNav";
import landingData from "../constant/landingData";
import { iconSize } from "../constant/sizes";
import useTrigger from "../global/useTrigger";
import Footer from "../landingSections/Footer";
import { getLang } from "../lib/lang";
import scrollTriggerConfig from "../lib/scrollTriggerConfig";

export default function ContactUsPage() {
  const { trigger } = useTrigger();
  const lang = getLang();
  const [loading, setLoading] = useState<boolean>(false);
  const containerRef = useRef(null);

  // Animation
  useGSAP(
    () => {
      gsap.from("#contactContent", {
        scrollTrigger: scrollTriggerConfig("#contactContent"),
        x: -300,
        opacity: 0,
      });

      gsap.from("#faqs", {
        scrollTrigger: scrollTriggerConfig("#faqsContent"),
        x: 300,
        opacity: 0,
      });
    },
    { scope: containerRef }
  );

  return (
    <VStack
      ref={containerRef}
      id="contact"
      align={"stretch"}
      // bg={"var(--darka)"}
      borderTop={"1px solid var(--divider3)"}
    >
      <Text display={"none"}>{trigger}</Text>
      <TopNav />

      <Container>
        <PageHeader>{landingData.contact[lang].title}</PageHeader>

        <VStack
          align={"stretch"}
          overflow={"hidden"}
          mb={20}
          position={"relative"}
        >
          <SimpleGrid columns={[1, null, 2]} gap={12}>
            <Box id={"contactContent"}>
              <VStack align={"stretch"}>
                <ContactForm setLoading={setLoading} />

                <Wrap my={8}>
                  <Text opacity={0.6}>
                    {landingData.contact[lang].form.discalimer}
                  </Text>
                  <Text fontWeight={600} as={Link} to={"/policy"}>
                    {landingData.contact[lang].form.termsAndConditions}
                  </Text>
                </Wrap>

                <Button
                  type="submit"
                  form="contactForm"
                  rightIcon={
                    <Icon as={RiArrowRightUpLine} fontSize={iconSize} />
                  }
                  w={["100%", null, "fit-content"]}
                  pl={6}
                  mt={5}
                  color={"white"}
                  className="btn-ap clicky"
                  h={"50px"}
                  flexShrink={0}
                  isLoading={loading}
                >
                  {landingData.contact[lang].form.send}
                </Button>

                {lang === "id" ? (
                  <Text opacity={0.6} mt={4}>
                    Atau hubungi kami melalui{" "}
                    <ChakraLink
                      style={{
                        color: "var(--p600)",
                        fontWeight: "bold !important",
                      }}
                      href={"https://wa.me/+6283836444099"}
                      isExternal
                    >
                      WhatsApp
                    </ChakraLink>{" "}
                    dan{" "}
                    <a
                      style={{
                        color: "var(--p600)",
                        fontWeight: "bold !important",
                      }}
                      href={"#sosmeds"}
                    >
                      Social Media
                    </a>{" "}
                    kami, ada di area footer
                  </Text>
                ) : (
                  <Text opacity={0.6} mt={4}>
                    Or contact us via{" "}
                    <ChakraLink
                      style={{
                        color: "var(--p600)",
                        fontWeight: "bold !important",
                      }}
                      href={"https://wa.me/+6283836444099"}
                      isExternal
                    >
                      WhatsApp
                    </ChakraLink>{" "}
                    and our{" "}
                    <a
                      style={{
                        color: "var(--p600)",
                        fontWeight: "bold !important",
                      }}
                      href={"#sosmeds"}
                    >
                      Social Media
                    </a>
                    , in the footer area
                  </Text>
                )}
              </VStack>
            </Box>

            <Box id="faqsContent">
              <VStack
                id="faqs"
                pt={6}
                pb={2}
                px={8}
                borderRadius={12}
                border={"1px solid var(--divider3)"}
                align={"stretch"}
              >
                <Heading as={"h1"} mb={8} fontWeight={600}>
                  FAQs
                </Heading>

                <Accordion allowToggle>
                  {landingData.contact[lang].faqs.map((faq, i) => (
                    <AccordionItem
                      key={i}
                      borderTop={i === 0 ? "none" : ""}
                      borderBottom={
                        i === landingData.contact[lang].faqs.length - 1
                          ? "none"
                          : ""
                      }
                      borderColor={"var(--divider3)"}
                    >
                      <h2>
                        <AccordionButton
                          py={4}
                          px={0}
                          as={HStack}
                          cursor={"pointer"}
                          className="btn-clear"
                        >
                          <Box as="span" flex="1" textAlign="left" mr={4}>
                            {faq.title}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>

                      <AccordionPanel opacity={0.6} lineHeight={1.6} px={0}>
                        {faq.description}
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </VStack>
            </Box>
          </SimpleGrid>
        </VStack>
      </Container>

      <Footer />
    </VStack>
  );
}
