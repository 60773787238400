import { ChakraProvider } from "@chakra-ui/react";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./globalStyle.css";
import Landing from "./pages/Landing";
import MissingPage from "./pages/MissingPage";
import { globalTheme } from "./theme/globalTheme";
import ServicesPage from "./pages/ServicesPage";
import WorksPage from "./pages/WorksPage";
import WorksDetailPage from "./pages/WorkDetailPage";
import AboutUsPage from "./pages/AboutUsPage";
import ContactUsPage from "./pages/ContactUsPage";
import FaqsPage from "./pages/FaqsPage";
import Policy from "./pages/Policy";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
  }, []);

  return (
    <ChakraProvider theme={globalTheme}>
      <BrowserRouter>
        <ScrollToTop />

        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/works" element={<WorksPage />} />
          <Route path="/works/:id" element={<WorksDetailPage />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/faqs" element={<FaqsPage />} />
          <Route path="/policy" element={<Policy />} />

          <Route path="*" element={<MissingPage />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
};

export default App;
