import { WorksData } from "./types";

const worksData: WorksData = {
  id: [
    {
      id: 0,
      category: 1,
      clientLogo: "/images/clients/gerindra.png",
      clientName: "PARTAI GERINDRA",
      title: "Manajemen",
      subTitle: "Tata Kelola Pendaftaran Calon Legislatif",
      image: "/images/works/thumbnail/0.png",
      imageAlter: "/images/works/thumbnailAlter/0_alter.png",
      solution:
        "Mengembangkan aplikasi yang mengotomatisasi proses pendaftaran calon legislatif, termasuk administrasi, verifikasi, dan pemantauan, untuk memastikan efisiensi, transparansi, dan akurasi data, serta mengurangi risiko kesalahan dan kecurangan.",
      narrative:
        "Proyek ini bertujuan untuk mengembangkan aplikasi yang memfasilitasi proses pendaftaran calon legislatif secara efisien dan transparan. Aplikasi ini akan mengotomatisasi berbagai tahapan administrasi, verifikasi, dan pemantauan pendaftaran, sehingga meningkatkan akurasi data dan mengurangi potensi kesalahan atau kecurangan.",
      ss: [
        "/images/works/0/ss/0.png",
        "/images/works/0/ss/1.png",
        "/images/works/0/ss/2.png",
        "/images/works/0/ss/3.png",
        "/images/works/0/ss/4.png",
        "/images/works/0/ss/5.png",
        "/images/works/0/ss/6.png",
        "/images/works/0/ss/7.png",
        "/images/works/0/ss/8.png",
        "/images/works/0/ss/9.png",
        "/images/works/0/ss/10.png",
      ],
      ssScrollLength: 450,
    },

    {
      id: 1,
      category: 1,
      clientLogo: "/images/clients/bpjs.png",
      clientName: "BPJS KESEHATAN",
      title: "Antisipasi Fraud",
      subTitle: "Sistem Antisipasi Fraud Berbasis Web dan Bot Telegram",
      image: "/images/works/thumbnail/1.png",
      imageAlter: "/images/works/thumbnailAlter/1_alter.png",
      solution:
        "Mengembangkan sistem yang terintegrasi antara platform web dan bot Telegram, menggunakan analitik canggih untuk deteksi fraud, serta menyediakan notifikasi real-time untuk respon cepat terhadap ancaman, sehingga meningkatkan keamanan dan keandalan sistem secara keseluruhan.",
      narrative:
        "Proyek ini bertujuan untuk mengembangkan sistem yang mendeteksi dan mencegah tindakan fraud secara efektif melalui platform web dan bot Telegram. Sistem ini akan memanfaatkan analitik canggih dan notifikasi real-time untuk mengidentifikasi dan menanggapi potensi ancaman fraud dengan cepat.",
      ss: [
        "/images/works/1/ss/0.png",
        "/images/works/1/ss/1.png",
        "/images/works/1/ss/2.png",
        "/images/works/1/ss/3.png",
        "/images/works/1/ss/4.png",
        "/images/works/1/ss/5.png",
        "/images/works/1/ss/6.png",
        "/images/works/1/ss/7.png",
        "/images/works/1/ss/8.png",
        "/images/works/1/ss/9.png",
      ],
      ssScrollLength: 450,
    },

    //! Bayu
    {
      id: 2,
      category: 2,
      clientLogo: "",
      clientName: "",
      title: "Pembayaran",
      subTitle: "Payment Point Online Banking : Payoll",
      image: "/images/works/thumbnail/2.png",
      imageAlter: "/images/works/thumbnailAlter/2_alter.png",
      solution:
        "Solusi terbaik untuk mengelola pembayaran tagihan. Dengan aplikasi mobile untuk iOS dan Android, serta dashboard pengelolaan yang intuitif, Anda dapat mengakses layanan pembayaran tagihan dengan mudah. Tidak hanya itu, Anda juga mendapatkan alat untuk melacak transaksi, mengelola agen, dan menganalisis kinerja bisnis Anda. Simplifikasi bisnis Anda dengan Payoll dan buka pintu menuju efisiensi yang lebih besar",
      narrative:
        "Payoll Merupakan solusi inovatif untuk membantu Anda mengelola pembayaran tagihan sehari-hari dengan lebih mudah dan efisien. Dengan Payoll, Anda dapat melakukan pembayaran berbagai tagihan seperti pulsa, paket data, uang elektronik, dan bahkan listrik PLN tanpa ribet. Kami hadir dengan komitmen untuk memberikan kemudahan akses dan fleksibilitas kepada pengguna kami. Melalui antarmuka yang ramah pengguna, kami memastikan bahwa setiap transaksi pembayaran dapat dilakukan dengan cepat dan tanpa hambatan. Kami mengutamakan kepuasan pengguna dengan menyediakan layanan pembayaran yang terpercaya, aman, dan efektif. Dengan Payoll, Anda dapat merasakan pengalaman pembayaran yang lebih praktis, menyenangkan, dan sesuai dengan kebutuhan Anda.",
      ss: ["/images/works/2/ss/0.png", "/images/works/2/ss/1.png"],
      ssScrollLength: 450,
    },

    {
      id: 3,
      category: 1,
      clientLogo: "",
      clientName: "",
      title: "Point of Sales",
      subTitle: "POSLite, Kelola Bisnis Resto Dengan Nyaman",
      image: "/images/works/thumbnail/3.png",
      imageAlter: "/images/works/thumbnailAlter/3_alter.png",
      solution:
        "Sebuah solusi digital yang disebut Point of Sales, dimana users dapat mengolola produk, analisis laporan keuangan, mengelola transaksi customers serta mempercepat kinerja mereka dalam mengembangkan bisnis mereka di era digital ini.",
      narrative:
        "Poslite adalah aplikasi lengkap yang menyederhanakan transaksi bagi pengguna dan memberdayakan pemilik bisnis dalam mengelola usaha mereka. Dengan kemampuan untuk mengatur pesanan dan meja secara rapi, serta memberikan informasi laporan keuangan bisnis secara menyeluruh, Poslite membantu meningkatkan efisiensi dan profitabilitas bisnis Anda. Simplifikasi pengalaman bisnis Anda dengan Poslite.",
      ss: [
        "/images/works/3/ss/0.png",
        "/images/works/3/ss/1.png",
        "/images/works/3/ss/2.png",
        "/images/works/3/ss/3.png",
      ],
      ssScrollLength: 450,
    },

    {
      id: 4,
      category: 0,
      clientLogo: "",
      clientName: "PT Digital Marketing Indonesia Maju",
      title: "Landing Page",
      subTitle: "PT Digital Marketing Indonesia Maju Landing Page",
      image: "/images/works/thumbnail/4.png",
      imageAlter: "/images/works/thumbnailAlter/4_alter.png",
      solution:
        "Kami telah melakukan Redesign pada website PT Digital Marketing Indonesia Maju untuk meningkatkan pengalaman pengguna dan membantu meningkatkan traffic serta konversi bisnis Anda. Landing page kami menyoroti layanan utama kami: Service, Portfolio, dan Kontak. Kami juga mengoptimalkan halaman blog untuk memaksimalkan performa konten artikel kami. Temukan lebih lanjut tentang bagaimana kami dapat membantu bisnis Anda berkembang di dunia digital dengan mengunjungi website kami hari ini!",
      narrative:
        "Sebuah agensi digital bernama PT Digital Marketing Indonesia Maju yang berlokasi di Indonesia, dengan kantor pusat di Jakarta. Saat ini telah memiliki 5 kantor cabang di beberapa kota (Malang, Surabaya, Bali, Bandung, dan Medan). Perusahaan tersebut bergerak di bidang layanan jasa website dan digital marketing specialist. Perusahaan tersebut saat ini telah membantu percepatan bisnis dari 700 customer lebih dalam kurun waktu 12 tahun. Di tahun ini, perusahaan tersebut sedang melakukan revamp terkait company profile website untuk meningkatkan traffic dan membantu konversi bisnis lebih baik. Dimana, fokus utama yang dituju adalah supaya user atau calon customer dapat masuk pada halaman service, portofolio, dan kontak. Selain itu, fokus perusahaan juga adalah untuk mengoptimalkan halaman blog dengan memaksimalkan performa untuk setiap konten artikel yang dibuat ",
      ss: [
        "/images/works/4/ss/0.png",
        "/images/works/4/ss/1.png",
        "/images/works/4/ss/2.png",
        "/images/works/4/ss/3.png",
        "/images/works/4/ss/4.png",
        "/images/works/4/ss/5.png",
        "/images/works/4/ss/6.png",
        "/images/works/4/ss/7.png",
        "/images/works/4/ss/8.png",
        "/images/works/4/ss/9.png",
        "/images/works/4/ss/10.png",
      ],
      ssScrollLength: 450,
    },

    {
      id: 5,
      category: 2,
      clientLogo: "",
      clientName: "",
      title: "E-Ticketing",
      subTitle: "Tixlive, Tiket Konser Rock yang Simpel dan Cepat",
      image: "/images/works/thumbnail/5.png",
      imageAlter: "/images/works/thumbnailAlter/5_alter.png",
      solution:
        "Membuat platform pembelian tiket konser rock yang intuitif dan responsif. Menyediakan informasi terbaru secara real-time, berbagai opsi pembayaran, dan dukungan pelanggan yang responsif. Memungkinkan pembelian tiket secara online dan offline untuk memperluas akses penggemar. Dengan fitur-fitur inovatif, Tixlive bertujuan menciptakan pengalaman konser yang tak terlupakan bagi penggemar musik rock.",
      narrative:
        "Tixlive merupakan aplikasi pembelian tiket konser rock yang simpel dan cepat - di mana pengalaman konser yang tak terlupakan menjadi lebih mudah dari sebelumnya. Cukup cari konser favorit Anda, pilih tempat duduk yang Anda inginkan, dan beli tiket dalam hitungan klik. Tidak hanya itu, Anda juga akan mendapatkan pemberitahuan langsung tentang penjualan awal dan perubahan jadwal, sehingga Anda tak akan ketinggalan momen berharga. Jadi, bergabunglah dengan kami dan nikmati pengalaman tanpa repot dalam membeli tiket konser bagi penggemar musik rock sejati!",
      ss: [
        "/images/works/5/ss/0.png",
        "/images/works/5/ss/1.png",
        "/images/works/5/ss/2.png",
        "/images/works/5/ss/3.png",
        "/images/works/5/ss/4.png",
      ],
      ssScrollLength: 450,
    },
    //! Bayu

    {
      id: 6,
      category: 0,
      clientLogo: "",
      clientName: "Kubu.id",
      title: "Landing Page",
      subTitle: "Landing Page Kubu.id yang Berisi Semua Web Kubu.id ",
      image: "/images/works/thumbnail/6.png",
      imageAlter: "/images/works/thumbnailAlter/6_alter.png",
      solution:
        "Membuat backend CMS (Content Management System) menggunakan laravel dan membuat frontend end user menggunakan React dan ChakraUI (+dark mode)",
      narrative:
        "Kubu.id adalah aplikasi dengan basis komunitas online untuk kamu yang sudah bekerja dan bagi calon pekerja. Tujuannya menjadi pusat pengetahuan dan informasi terkait ketenagakerjaan, sebagai media untuk konsultasi online dengan profesional, mengembangkan jaringan dan saling berbagi informasi.",
      ss: [
        "/images/works/6/ss/0.png",
        "/images/works/6/ss/1.png",
        "/images/works/6/ss/2.png",
        "/images/works/6/ss/3.png",
        "/images/works/6/ss/4.png",
        "/images/works/6/ss/5.png",
      ],
      ssScrollLength: 450,
    },

    {
      id: 7,
      category: 1,
      clientLogo: "",
      clientName: "Kubu.id",
      title: "Admin App",
      subTitle:
        "Webapp Admin untuk Mengelola Situs Web Landing Page, Blog, Online Course",
      image: "/images/works/thumbnail/7.png",
      imageAlter: "/images/works/thumbnailAlter/7_alter.png",
      solution:
        "Membuat backend menggunakan laravel, yang pertama dibuat adalah sistem role dahulu (create akun admin). Kemudian membuat logic CRUD blog dan course.",
      narrative:
        "Dibutuhkan webapp admin untuk mengelola website-website yang akan dibuat nanti. Aksi seperti membuat, memperbarui, menghapus, restore harus ada dan webapp admin ini juga memiliki role admin tertentu seperti admin online course, admin blog, dll.",
      ss: [
        "/images/works/7/ss/0.png",
        "/images/works/7/ss/1.png",
        "/images/works/7/ss/2.png",
        "/images/works/7/ss/3.png",
        "/images/works/7/ss/4.png",
        "/images/works/7/ss/5.png",
        "/images/works/7/ss/6.png",
        "/images/works/7/ss/7.png",
        "/images/works/7/ss/8.png",
      ],
      ssScrollLength: 450,
    },

    {
      id: 8,
      category: 1,
      clientLogo: "",
      clientName: "Kubu.id",
      title: "Blog",
      subTitle: "Blog Kubu.id yang Berisi Artikel Tentang Pekerja Informal",
      image: "/images/works/thumbnail/8.png",
      imageAlter: "/images/works/thumbnailAlter/7_alter.png",
      solution:
        "Membuat backend manajemen blog menggunakan laravel dan membuat frontend end user menggunakan React dan ChakraUI (+dark mode)",
      narrative:
        "Kubu.id menawarkan platform web blog yang unik, berfokus pada ketenagakerjaan untuk para profesional yang sudah bekerja maupun calon pekerja. Di sini, pengguna dapat menemukan beragam artikel, panduan, dan tips terkait dunia kerja, serta konsultasi online dengan para profesional terkait. Selain itu, kubu.id juga menjadi tempat untuk membangun jaringan dan berbagi pengalaman antara sesama anggota komunitas. Dengan kombinasi pengetahuan, konsultasi, dan interaksi antaranggota, kubu.id menjadi sumber daya yang berharga bagi siapa pun yang ingin sukses dalam karir mereka.",
      ss: [
        "/images/works/8/ss/0.png",
        "/images/works/8/ss/1.png",
        "/images/works/8/ss/2.png",
        "/images/works/8/ss/3.png",
        "/images/works/8/ss/4.png",
        "/images/works/8/ss/5.png",
      ],
      ssScrollLength: 450,
    },

    {
      id: 9,
      category: 1,
      clientLogo: "",
      clientName: "Kubu.id",
      title: "Online Course",
      subTitle: "Online Course dari Kubu.id Berbasis Web",
      image: "/images/works/thumbnail/9.png",
      imageAlter: "/images/works/thumbnailAlter/9_alter.png",
      solution:
        "Membuat beberapa backend menggunakan laravel dan membuat frontend end user menggunakan React dan ChakraUI",
      narrative:
        "Web online course ini ditujukan untuk orang yang ingin mengikuti proses pembelajaran dengan cara daring. Kurikulum terstruktur dan akses video selanjutnya bisa diakses hanya dengan cara menyelesaikan video yang tersedia. Playback video tidak bisa dimajukan namun bisa disimpan max played nya.",
      ss: [
        "/images/works/9/ss/0.png",
        "/images/works/9/ss/1.png",
        "/images/works/9/ss/2.png",
        "/images/works/9/ss/3.png",
        "/images/works/9/ss/4.png",
        "/images/works/9/ss/5.png",
        "/images/works/9/ss/6.png",
      ],
      ssScrollLength: 450,
    },

    {
      id: 10,
      category: 1,
      clientLogo: "/images/clients/poltekkes.png",
      clientName: "POLTEKKES SEMARANG",
      title: "Tes Lab",
      subTitle:
        "Platform untuk Transaksi Pengujian Laboratorium dan Dasboard Admin",
      image: "/images/works/thumbnail/10.png",
      imageAlter: "/images/works/thumbnailAlter/10_alter.png",
      solution:
        "Membuat webapp admin untuk mengelola master data dan transaksi yang ada, membuat webapp untuk customer yang digunakan untuk pengajuan pengujian",
      narrative:
        "Poltekkes meminta sebuah sistem untuk pengujian lab. Jadi aa 2 pengguna yaitu customer dan admin. Customer dapat membuat akun dan mengajukan pengujian laboratorium dan memantau prosesnya. Admin dapat mengelola sistem tersebut seperti metode pengujian, biaya, dashboard dll.",
      ss: [
        "/images/works/10/ss/0.png",
        "/images/works/10/ss/1.png",
        "/images/works/10/ss/2.png",
        "/images/works/10/ss/3.png",
        "/images/works/10/ss/4.png",
        "/images/works/10/ss/5.png",
        "/images/works/10/ss/6.png",
        "/images/works/10/ss/7.png",
        "/images/works/10/ss/8.png",
        "/images/works/10/ss/9.png",
        "/images/works/10/ss/10.png",
        "/images/works/10/ss/11.png",
        "/images/works/10/ss/12.png",
        "/images/works/10/ss/13.png",
        "/images/works/10/ss/14.png",
        "/images/works/10/ss/15.png",
        "/images/works/10/ss/16.png",
        "/images/works/10/ss/17.png",
      ],
      ssScrollLength: 450,
    },

    {
      id: 11,
      category: 1,
      clientLogo: "/images/clients/rski.png",
      clientName: "RUMAH SAKIT KASIH IBU",
      title: "HR App",
      subTitle: "Aplikasi HR untuk Rumah Sakit Kasih Ibu",
      image: "/images/works/thumbnail/11.png",
      imageAlter: "/images/works/thumbnailAlter/11_alter.png",
      solution:
        "Membuat webapp admin untuk mengelola master data dan presensi karyawan. Terdapat fitur pembuatan master data data karyawan, shift, keuangan,penggajian hingga pengaturan THR",
      narrative:
        "Data yang tersebar di berbagai departemen menyebabkan pengolahan informasi menjadi lambat dan rentan terhadap kesalahan. Untuk mengatasi masalah ini, RSKI memutuskan untuk membuat sebuah webapp admin yang mampu mengelola seluruh master data dan presensi karyawan secara terpusat dan otomatis.",
      ss: [
        "/images/works/11/ss/0.png",
        "/images/works/11/ss/1.png",
        "/images/works/11/ss/2.png",
        "/images/works/11/ss/3.png",
        "/images/works/11/ss/4.png",
        "/images/works/11/ss/5.png",
        "/images/works/11/ss/6.png",
        "/images/works/11/ss/7.png",
        "/images/works/11/ss/8.png",
        "/images/works/11/ss/9.png",
        "/images/works/11/ss/10.png",
        "/images/works/11/ss/11.png",
        "/images/works/11/ss/12.png",
        "/images/works/11/ss/13.png",
        "/images/works/11/ss/14.png",
        "/images/works/11/ss/15.png",
        "/images/works/11/ss/16.png",
        "/images/works/11/ss/17.png",
        "/images/works/11/ss/18.png",
      ],
      ssScrollLength: 450,
    },

    {
      id: 12,
      category: 2,
      clientLogo: "/images/clients/rski.png",
      clientName: "RUMAH SAKIT KASIH IBU",
      title: "Presensi",
      subTitle: "Aplikasi Karyawan untuk Rumah Sakit Kasih Ibu",
      image: "/images/works/thumbnail/12.png",
      imageAlter: "/images/works/thumbnailAlter/12_alter.png",
      solution:
        "Membuat mobile app dan PWA untuk digunakan karyawan. Fitur utama pada aplikasi ini adalah presensi dengan foto dan geolokasi. Terdapat fitur lain dari permohonan cuti/izin, tukar shift, slip gaji. laporan hingga koperasi. Terintegrasi dengan alat fingerprint",
      narrative:
        "Data presensi menggunakan alat fingerprint sangat sulit untuk dikelola karena harus export dari alatnya dan kemudian di audit kembali yang memakan waktu cukup lama. Sistem informasi karyawan juga diperlukan untuk pengelolaan data karyawan agar semakin efisien.",
      ss: [
        "/images/works/12/ss/0.png",
        "/images/works/12/ss/1.png",
        "/images/works/12/ss/2.png",
        "/images/works/12/ss/3.png",
        "/images/works/12/ss/4.png",
        "/images/works/12/ss/5.png",
      ],
      ssScrollLength: 450,
    },
  ],

  en: [
    {
      id: 0,
      category: 1,
      clientLogo: "/images/clients/gerindra.png",
      clientName: "PARTAI GERINDRA",
      title: "Management App",
      subTitle: "Legislative Candidate Registration Governance",
      image: "/images/works/thumbnail/0.png",
      imageAlter: "/images/works/thumbnailAlter/0_alter.png",
      solution:
        "Develop an application that automates the legislative candidate registration process, including administration, verification, and monitoring, to ensure efficiency, transparency, and data accuracy, while reducing the risk of errors and fraud.",
      narrative:
        "This project aims to develop an application that facilitates the legislative candidate registration process efficiently and transparently. The application will automate various stages of administration, verification, and monitoring, thereby enhancing data accuracy and reducing the potential for errors or fraud.",
      ss: [
        "/images/works/0/ss/0.png",
        "/images/works/0/ss/1.png",
        "/images/works/0/ss/2.png",
        "/images/works/0/ss/3.png",
        "/images/works/0/ss/4.png",
        "/images/works/0/ss/5.png",
        "/images/works/0/ss/6.png",
        "/images/works/0/ss/7.png",
        "/images/works/0/ss/8.png",
        "/images/works/0/ss/9.png",
        "/images/works/0/ss/10.png",
      ],
      ssScrollLength: 450,
    },

    {
      id: 1,
      category: 1,
      clientLogo: "/images/clients/bpjs.png",
      clientName: "BPJS KESEHATAN",
      title: "Fraud Anticipation",
      subTitle: "Web-Based and Telegram Bot Fraud Anticipation System",
      image: "/images/works/thumbnail/1.png",
      imageAlter: "/images/works/thumbnailAlter/1_alter.png",
      solution:
        "Develop a system integrated between web platform and Telegram bot, utilizing advanced analytics for fraud detection, and providing real-time notifications for quick response to threats, thereby enhancing overall system security and reliability.",
      narrative:
        "This project aims to develop a system that effectively detects and prevents fraud through a web platform and Telegram bot. The system will leverage advanced analytics and real-time notifications to quickly identify and respond to potential fraud threats.",
      ss: [
        "/images/works/1/ss/0.png",
        "/images/works/1/ss/1.png",
        "/images/works/1/ss/2.png",
        "/images/works/1/ss/3.png",
        "/images/works/1/ss/4.png",
        "/images/works/1/ss/5.png",
        "/images/works/1/ss/6.png",
        "/images/works/1/ss/7.png",
        "/images/works/1/ss/8.png",
        "/images/works/1/ss/9.png",
      ],
      ssScrollLength: 450,
    },

    //! Bayu
    {
      id: 2,
      category: 2,
      clientLogo: "",
      clientName: "",
      title: "Payment",
      subTitle: "Payment Point Online Banking: Payroll",
      image: "/images/works/thumbnail/2.png",
      imageAlter: "/images/works/thumbnailAlter/2_alter.png",
      solution:
        "The best solution for managing bill payments. With a mobile application for iOS and Android, as well as an intuitive management dashboard, you can easily access bill payment services. Not only that, you also get tools to track transactions, manage agents, and analyze your business performance. Simplify your business with Payroll and open the door to greater efficiency.",
      narrative:
        "Payroll is an innovative solution to help you manage daily bill payments more easily and efficiently. With Payroll, you can make payments for various bills such as phone credit, data packages, electronic money, and even PLN electricity without hassle. We are committed to providing ease of access and flexibility to our users. Through a user-friendly interface, we ensure that every payment transaction can be done quickly and smoothly. We prioritize user satisfaction by providing reliable, secure, and effective payment services. With Payroll, you can experience a more practical, enjoyable, and tailored payment experience.",
      ss: ["/images/works/2/ss/0.png", "/images/works/2/ss/1.png"],
      ssScrollLength: 450,
    },

    {
      id: 3,
      category: 1,
      clientLogo: "",
      clientName: "",
      title: "Point of Sales",
      subTitle: "POSLite, Manage Your Restaurant Business Comfortably",
      image: "/images/works/thumbnail/3.png",
      imageAlter: "/images/works/thumbnailAlter/3_alter.png",
      solution:
        "A digital solution called Point of Sales, where users can manage products, analyze financial reports, manage customer transactions, and accelerate their performance in developing their business in this digital era.",
      narrative:
        "Poslite is a comprehensive application that simplifies transactions for users and empowers business owners to manage their businesses. With the ability to organize orders and tables neatly, as well as provide comprehensive business financial report information, Poslite helps increase efficiency and profitability of your business. Simplify your business experience with Poslite.",
      ss: [
        "/images/works/3/ss/0.png",
        "/images/works/3/ss/1.png",
        "/images/works/3/ss/2.png",
        "/images/works/3/ss/3.png",
      ],
      ssScrollLength: 450,
    },

    {
      id: 4,
      category: 0,
      clientLogo: "",
      clientName: "PT Digital Marketing Indonesia Maju",
      title: "Landing Page",
      subTitle: "PT Digital Marketing Indonesia Maju Landing Page",
      image: "/images/works/thumbnail/4.png",
      imageAlter: "/images/works/thumbnailAlter/4_alter.png",
      solution:
        "We have redesigned the PT Digital Marketing Indonesia Maju website to enhance user experience and help increase traffic and business conversions. Our landing page highlights our main services: Service, Portfolio, and Contact. We also optimize the blog page to maximize the performance of our article content. Find out more about how we can help your business grow in the digital world by visiting our website today!",
      narrative:
        "A digital agency named PT Digital Marketing Indonesia Maju located in Indonesia, with headquarters in Jakarta. It currently has 5 branch offices in several cities (Malang, Surabaya, Bali, Bandung, and Medan). The company operates in the field of website services and digital marketing specialists. The company has helped accelerate the business of over 700 customers in a period of 12 years. This year, the company is revamping its company profile website to increase traffic and help better business conversions. The main focus is to ensure that users or potential customers can access the service, portfolio, and contact pages. Additionally, the company's focus is to optimize the blog page by maximizing the performance for each article content.",
      ss: [
        "/images/works/4/ss/0.png",
        "/images/works/4/ss/1.png",
        "/images/works/4/ss/2.png",
        "/images/works/4/ss/3.png",
        "/images/works/4/ss/4.png",
        "/images/works/4/ss/5.png",
        "/images/works/4/ss/6.png",
        "/images/works/4/ss/7.png",
        "/images/works/4/ss/8.png",
        "/images/works/4/ss/9.png",
        "/images/works/4/ss/10.png",
      ],
      ssScrollLength: 450,
    },

    {
      id: 5,
      category: 2,
      clientLogo: "",
      clientName: "",
      title: "E-Ticketing",
      subTitle: "Tixlive, Simple and Fast Rock Concert Tickets",
      image: "/images/works/thumbnail/5.png",
      imageAlter: "/images/works/thumbnailAlter/5_alter.png",
      solution:
        "Creating an intuitive and responsive platform for purchasing rock concert tickets. Providing real-time updates, various payment options, and responsive customer support. Allowing both online and offline ticket purchases to expand fan access. With innovative features, Tixlive aims to create an unforgettable concert experience for rock music fans.",
      narrative:
        "Tixlive is a simple and fast rock concert ticket purchasing application - where the unforgettable concert experience becomes easier than ever. Simply search for your favorite concert, choose your desired seats, and buy tickets in just a few clicks. Not only that, you will also receive instant notifications about early sales and schedule changes, so you won't miss any precious moments. So, join us and enjoy a hassle-free ticket purchasing experience for true rock music fans!",
      ss: [
        "/images/works/5/ss/0.png",
        "/images/works/5/ss/1.png",
        "/images/works/5/ss/2.png",
        "/images/works/5/ss/3.png",
        "/images/works/5/ss/4.png",
      ],
      ssScrollLength: 450,
    },
    //! Bayu

    {
      id: 6,
      category: 0,
      clientLogo: "",
      clientName: "Kubu.id",
      title: "Landing Page",
      subTitle: "Kubu.id Landing Page Containing All Kubu.id Websites",
      image: "/images/works/thumbnail/6.png",
      imageAlter: "/images/works/thumbnailAlter/6_alter.png",
      solution:
        "Creating a CMS (Content Management System) backend using Laravel and creating a user frontend using React and ChakraUI (+dark mode)",
      narrative:
        "Kubu.id is an application with an online community base for those of you who are already working and for prospective workers. The aim is to become a center for knowledge and information related to employment, as a medium for online consultations with professionals, developing networks and sharing information.",
      ss: [
        "/images/works/6/ss/0.png",
        "/images/works/6/ss/1.png",
        "/images/works/6/ss/2.png",
        "/images/works/6/ss/3.png",
        "/images/works/6/ss/4.png",
        "/images/works/6/ss/5.png",
      ],
      ssScrollLength: 450,
    },

    {
      id: 7,
      category: 1,
      clientLogo: "",
      clientName: "Kubu.id",
      title: "Admin App",
      subTitle:
        "Webapp Admin to Manage Website Landing Pages, Blogs, Online Courses",
      image: "/images/works/thumbnail/7.png",
      imageAlter: "/images/works/thumbnailAlter/7_alter.png",
      solution:
        "Creating a backend using Laravel, the first thing to create is the role system (create an admin account). Then create the CRUD blog and course logic.",
      narrative:
        "We need an admin webapp to manage the existing websites made later. Actions such as create, update, delete, restore must be present and this admin webapp also has certain admin roles such as online course admin, blog admin, etc.",
      ss: [
        "/images/works/7/ss/0.png",
        "/images/works/7/ss/1.png",
        "/images/works/7/ss/2.png",
        "/images/works/7/ss/3.png",
        "/images/works/7/ss/4.png",
        "/images/works/7/ss/5.png",
        "/images/works/7/ss/6.png",
        "/images/works/7/ss/7.png",
        "/images/works/7/ss/8.png",
      ],
      ssScrollLength: 450,
    },

    {
      id: 8,
      category: 1,
      clientLogo: "",
      clientName: "Kubu.id",
      title: "Blog",
      subTitle: "Kubu.id Blog Contain Articles About Informal Workers",
      image: "/images/works/thumbnail/8.png",
      imageAlter: "/images/works/thumbnailAlter/8_alter.png",
      solution:
        "Creating a blog management backend using Laravel and creating a user frontend using React and ChakraUI (+dark mode)",
      narrative:
        "Kubu.id offers a unique web blog platform, focused on employment for professionals who are already working and prospective workers. Here, users can find various articles, guides and tips related to the world of work, as well as online consultations with related professionals. Apart from Kubu.id is also a place to build networks and share experiences between fellow community members. With a combination of knowledge, consultation and interaction between members, Kubu.id is a valuable resource for anyone who wants to be successful in their career.",
      ss: [
        "/images/works/8/ss/0.png",
        "/images/works/8/ss/1.png",
        "/images/works/8/ss/2.png",
        "/images/works/8/ss/3.png",
        "/images/works/8/ss/4.png",
        "/images/works/8/ss/5.png",
      ],
      ssScrollLength: 450,
    },

    {
      id: 9,
      category: 1,
      clientLogo: "",
      clientName: "Kubu.id",
      title: "Online Course",
      subTitle: "Web Based Online Course from Kubu.id",
      image: "/images/works/thumbnail/9.png",
      imageAlter: "/images/works/thumbnailAlter/9_alter.png",
      solution:
        "Creating several backends using Laravel and creating a user frontend using React and ChakraUI",
      narrative:
        "This online web course is intended for people who want to follow the learning process online. The structured curriculum and subsequent video access can be accessed simply by completing the available videos. Video playback cannot be advanced but the max played can be saved.",
      ss: [
        "/images/works/9/ss/0.png",
        "/images/works/9/ss/1.png",
        "/images/works/9/ss/2.png",
        "/images/works/9/ss/3.png",
        "/images/works/9/ss/4.png",
        "/images/works/9/ss/5.png",
        "/images/works/9/ss/6.png",
      ],
      ssScrollLength: 450,
    },

    {
      id: 10,
      category: 1,
      clientLogo: "/images/clients/poltekkes.png",
      clientName: "POLTEKKES SEMARANG",
      title: "Test Lab",
      subTitle: "Platform for Laboratory Testing Transactions and Admin App",
      image: "/images/works/thumbnail/10.png",
      imageAlter: "/images/works/thumbnailAlter/10_alter.png",
      solution:
        "Create an admin webapp to manage existing master data and transactions, create a webapp for customers that is used for testing submissions",
      narrative:
        "Poltekkes asks for a system for lab testing. So there are 2 users, namely customer and admin. Customers can create accounts and submit laboratory tests and monitor the process. Admin can manage the system such as test methods, costs, dashboards, etc.",
      ss: [
        "/images/works/10/ss/0.png",
        "/images/works/10/ss/1.png",
        "/images/works/10/ss/2.png",
        "/images/works/10/ss/3.png",
        "/images/works/10/ss/4.png",
        "/images/works/10/ss/5.png",
        "/images/works/10/ss/6.png",
        "/images/works/10/ss/7.png",
        "/images/works/10/ss/8.png",
        "/images/works/10/ss/9.png",
        "/images/works/10/ss/10.png",
        "/images/works/10/ss/11.png",
        "/images/works/10/ss/12.png",
        "/images/works/10/ss/13.png",
        "/images/works/10/ss/14.png",
        "/images/works/10/ss/15.png",
        "/images/works/10/ss/16.png",
        "/images/works/10/ss/17.png",
      ],
      ssScrollLength: 450,
    },

    {
      id: 11,
      category: 1,
      clientLogo: "/images/clients/rski.png",
      clientName: "RUMAH SAKIT KASIH IBU",
      title: "HR App",
      subTitle: "HR Application for Rumah Sakit Kasih Ibu",
      image: "/images/works/thumbnail/11.png",
      imageAlter: "/images/works/thumbnailAlter/11_alter.png",
      solution:
        "Creating an admin webapp to manage employee master data and attendance. It includes features for creating employee master data, shifts, finances, payroll, and setting THR.",
      narrative:
        "Data scattered across various departments causes information processing to be slow and prone to errors. To address this issue, RSKI decided to create an admin webapp capable of centrally and automatically managing all employee master data and attendance.",
      ss: [
        "/images/works/11/ss/0.png",
        "/images/works/11/ss/1.png",
        "/images/works/11/ss/2.png",
        "/images/works/11/ss/3.png",
        "/images/works/11/ss/4.png",
        "/images/works/11/ss/5.png",
        "/images/works/11/ss/6.png",
        "/images/works/11/ss/7.png",
        "/images/works/11/ss/8.png",
        "/images/works/11/ss/9.png",
        "/images/works/11/ss/10.png",
        "/images/works/11/ss/11.png",
        "/images/works/11/ss/12.png",
        "/images/works/11/ss/13.png",
        "/images/works/11/ss/14.png",
        "/images/works/11/ss/15.png",
        "/images/works/11/ss/16.png",
        "/images/works/11/ss/17.png",
        "/images/works/11/ss/18.png",
      ],
      ssScrollLength: 450,
    },

    {
      id: 12,
      category: 2,
      clientLogo: "/images/clients/rski.png",
      clientName: "RUMAH SAKIT KASIH IBU",
      title: "Attendance",
      subTitle: "Employee Application for Rumah Sakit Kasih Ibu",
      image: "/images/works/thumbnail/12.png",
      imageAlter: "/images/works/thumbnailAlter/12_alter.png",
      solution:
        "Creating a mobile app and PWA for employee use. The main feature of this application is attendance with photo and geolocation. Other features include leave/permit requests, shift exchange, salary slip, reports, and cooperatives. Integrated with fingerprint devices.",
      narrative:
        "Managing attendance data using fingerprint devices is very difficult because it has to be exported from the device and then audited again, which takes quite a long time. An employee information system is also needed for efficient employee data management.",
      ss: [
        "/images/works/12/ss/0.png",
        "/images/works/12/ss/1.png",
        "/images/works/12/ss/2.png",
        "/images/works/12/ss/3.png",
        "/images/works/12/ss/4.png",
        "/images/works/12/ss/5.png",
      ],
      ssScrollLength: 450,
    },
  ],
};

export default worksData;
