import {
  Box,
  HStack,
  Heading,
  Icon,
  IconButton,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useGSAP } from "@gsap/react";
import { RiArrowLeftSLine, RiArrowRightSLine } from "@remixicon/react";
import gsap from "gsap";
import { useRef } from "react";
import Container from "../components/Container";
import StarRate from "../components/StarRate";
import clientStories from "../constant/clientStories";
import landingData from "../constant/landingData";
import useScreenWidth from "../hooks/useScreenWidth";
import { getLang } from "../lib/lang";
import scrollTriggerConfig from "../lib/scrollTriggerConfig";

export default function OurClients() {
  const lang = getLang();
  const containerRef = useRef<HTMLDivElement>(null);
  const carouselRef = useRef<HTMLDivElement>(null);
  const sw = useScreenWidth();

  const handlePrev = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += -300;
    }
  };
  const handleNext = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += 300;
    }
  };

  const containerClientRef = useRef(null);
  const containerSocialProofRef = useRef(null);

  // Animation
  useGSAP(
    () => {
      gsap.from([".sectionTitle", ".sectionnarrative"], {
        scrollTrigger: scrollTriggerConfig("#clientsContent"),
        y: 50,
        opacity: 0,
        stagger: {
          each: 0.2,
        },
      });

      gsap.from(".clientItem", {
        scrollTrigger: scrollTriggerConfig("#clientsContent"),
        scale: 0.5,
        opacity: 0,
        stagger: {
          each: 0.2,
        },
      });
    },
    { scope: containerClientRef }
  );

  useGSAP(
    () => {
      gsap.from([".sectionTitle", ".sectionnarrative"], {
        scrollTrigger: scrollTriggerConfig("#socialProofContent"),
        y: 50,
        opacity: 0,
        stagger: {
          each: 0.2,
        },
      });

      gsap.from([".socialProofItem"], {
        scrollTrigger: scrollTriggerConfig("#socialProofContent"),
        scale: 0.5,
        opacity: 0,
        stagger: {
          each: 0.05,
        },
      });

      gsap.from("#carouselControl", {
        scrollTrigger: scrollTriggerConfig("#socialProofContent"),
        scale: 0.5,
        opacity: 0,
        delay: 0.5,
      });
    },
    { scope: containerSocialProofRef }
  );

  return (
    <VStack id="ourClients" align={"stretch"} gap={0}>
      <Box ref={containerClientRef} id={"clients"} pt={20}>
        <Container id={"clientsContent"}>
          <VStack align={"stretch"}>
            <Heading
              as={"h1"}
              className="sectionTitle"
              textAlign={"center"}
              mb={8}
            >
              {landingData.clients[lang].title}
            </Heading>

            <Text
              className="sectionnarrative"
              textAlign={"center"}
              opacity={0.6}
              // mb={10}
            >
              {landingData.clients[lang].subtitle}
            </Text>

            <SimpleGrid mt={4} columns={[2, null, 4]} gap={4} borderRadius={12}>
              {landingData.clients.items.map((client, i) => (
                <VStack
                  className="clientItem"
                  key={i}
                  // border={"1px solid var(--divider3)"}
                  borderRadius={12}
                  justify={"center"}
                  p={[4, null, 8]}
                  // bg={"white"}
                >
                  <Image
                    alt={`Logo ${client.name}`}
                    loading={"lazy"}
                    src={client.logo}
                    h={"140px"}
                    objectFit={"contain"}
                    borderRadius={"0 !important"}
                  />
                </VStack>
              ))}
            </SimpleGrid>
          </VStack>
        </Container>
      </Box>

      <Box ref={containerSocialProofRef} id="socialProof" pt={20} pb={20}>
        <VStack
          id={"socialProofContent"}
          position={"relative"}
          overflow={"hidden"}
          gap={0}
        >
          <Container>
            <VStack align={"stretch"}>
              <Heading
                as={"h1"}
                className="sectionTitle"
                textAlign={"center"}
                mb={8}
              >
                {clientStories[lang].title}
              </Heading>

              <Text
                className="sectionnarrative"
                textAlign={"center"}
                opacity={0.6}
                mb={4}
              >
                {clientStories[lang].subtitle}
              </Text>
            </VStack>
          </Container>

          <Box w={"100%"}>
            <VStack
              w={"100%"}
              className="noScroll"
              overflow={"auto"}
              ref={containerRef}
              scrollBehavior={"smooth"}
              align={"flex-start"}
              scrollSnapType={"x mandatory"}
            >
              <HStack
                mx={"auto"}
                ref={carouselRef}
                w={"max-content"}
                align={"stretch"}
                gap={5}
                px={
                  sw < 1440
                    ? [5, null, 8]
                    : `calc((100vw - 1440px + ${sw < 768 ? "40px" : "64px"})/2)`
                }
                py={4}
              >
                {clientStories[lang].items.map((client, i) => (
                  <VStack className="socialProofItem" key={i} flexGrow={1}>
                    <VStack
                      flex={1}
                      scrollSnapAlign={"center"}
                      transition={"200ms"}
                      borderRadius={12}
                      // border={"1px solid var(--divider2)"}
                      cursor={"default"}
                      // _hover={{ transform: "translateY(-20px)" }}
                      w={"100%"}
                      maxW={["280px", null, "400px"]}
                      // bg={bgTestiCard}
                      align={"stretch"}
                      backdropFilter={"blur(5px)"}
                    >
                      <VStack align={"stretch"} gap={0} p={5} flex={1}>
                        <HStack mb={4}>
                          <StarRate rate={client.rate} />
                        </HStack>

                        <HStack align={"flex-start"}>
                          <Text fontSize={[16, null, 18]}>{client.quote}</Text>
                        </HStack>

                        <VStack align={"stretch"} gap={0} mt={"auto"}></VStack>
                      </VStack>
                    </VStack>
                  </VStack>
                ))}
              </HStack>
            </VStack>

            <Container id="carouselControl" mt={4}>
              <HStack ml={"auto"} mr={[5, null, 8]}>
                <IconButton
                  onClick={handlePrev}
                  aria-label="Prev"
                  icon={<Icon as={RiArrowLeftSLine} fontSize={24} />}
                  className="btn-solid clikcy"
                  // color={"white"}
                />

                <IconButton
                  onClick={handleNext}
                  aria-label="Prev"
                  icon={<Icon as={RiArrowRightSLine} fontSize={24} />}
                  className="btn-solid clikcy"
                  // color={"white"}
                />
              </HStack>
            </Container>
          </Box>
        </VStack>
      </Box>
    </VStack>
  );
}
