import {
  FormControl,
  FormErrorMessage,
  Input,
  SimpleGrid, 
  Text,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import emailjs from "@emailjs/browser";
import { useFormik } from "formik";
import { useRef } from "react";
import * as yup from "yup";
import landingData from "../constant/landingData";
import { getLang } from "../lib/lang";

interface Props {
  setLoading: (state: boolean) => void;
}

export default function ContactForm({ setLoading }: Props) {
  const lang = getLang();
  const contactForm = useRef<HTMLFormElement>(null);
  const toast = useToast();

  const formik = useFormik({
    validateOnChange: false,

    initialValues: {
      name: "",
      email: "",
      telepon: "",
      negara: "",
      perusahaan: "",
      message: "",
    },

    validationSchema: yup.object().shape({
      name: yup
        .string()
        .required(lang === "id" ? "Nama harus diisi" : "Name required"),
      email: yup
        .string()
        .required(lang === "id" ? "Email harus diisi" : "Email required"),
      telepon: yup
        .string()
        .required(lang === "id" ? "Telepon harus diisi" : "Phone required"),
      negara: yup
        .string()
        .required(lang === "id" ? "Negara harus diisi" : "Country required"),
      perusahaan: yup
        .string()
        .required(
          lang === "id" ? "Perusahaan harus diisi" : "Company required"
        ),
      message: yup
        .string()
        .required(lang === "id" ? "Pesan harus diisi" : "Message required"),
    }),

    onSubmit: (values) => {
      console.log(values);

      if (contactForm.current) {
        setLoading(true);
        emailjs
          .sendForm(
            import.meta.env.VITE_EMAIL_SERVICE_ID as string,
            import.meta.env.VITE_EMAIL_TEMPLATE_ID as string,
            contactForm.current,
            import.meta.env.VITE_EMAIL_PUBLIC_KEY as string
          )
          .then(
            () => {
              console.log("SUCCESS!");
              toast({
                title: `${
                  lang === "id"
                    ? "Pesan Berhasil Dikirim"
                    : "Message Sent Successfully"
                }`,
                render: () => (
                  <VStack color="white" p={3} bg="p.500" borderRadius={8}>
                    <Text>
                      {lang === "id"
                        ? "Pesan Berhasil Dikirim"
                        : "Message was sent"}
                    </Text>
                  </VStack>
                ),
                isClosable: true,
              });
              // formik.resetForm(); // Reset form after successful submission
            },
            (error) => {
              console.log("FAILED...", error.text);
              toast({
                title: "Pesan Tidak Terkirim",
                status: "error",
                isClosable: true,
              });
            }
          )
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });

  return (
    <form ref={contactForm} id="contactForm" onSubmit={formik.handleSubmit}>
      <VStack align={"stretch"} gap={8}>
        <SimpleGrid columns={[1, 2]} gap={8}>
          <FormControl isInvalid={formik.errors.name ? true : false}>
            <Input
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              className="inputContact"
              placeholder={landingData.contact[lang].form.name}
              variant={"flushed"}
              boxShadow={
                formik.errors.name ? "0px 1px 0px 0px #E53E3E !important" : ""
              }
            />
            <FormErrorMessage color={"red.400"}>
              {formik.errors.name}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={formik.errors.email ? true : false}>
            <Input
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              className="inputContact"
              placeholder={landingData.contact[lang].form.email}
              variant={"flushed"}
              boxShadow={
                formik.errors.email ? "0px 1px 0px 0px #E53E3E !important" : ""
              }
            />
            <FormErrorMessage color={"red.400"}>
              {formik.errors.email}
            </FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <SimpleGrid columns={[1, 2]} gap={8}>
          <FormControl isInvalid={formik.errors.telepon ? true : false}>
            <Input
              name="telepon"
              onChange={formik.handleChange}
              value={formik.values.telepon}
              className="inputContact"
              placeholder={landingData.contact[lang].form.phone}
              variant={"flushed"}
              boxShadow={
                formik.errors.telepon
                  ? "0px 1px 0px 0px #E53E3E !important"
                  : ""
              }
            />
            <FormErrorMessage color={"red.400"}>
              {formik.errors.telepon}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={formik.errors.negara ? true : false}>
            <Input
              name="negara"
              onChange={formik.handleChange}
              value={formik.values.negara}
              className="inputContact"
              placeholder={landingData.contact[lang].form.country}
              variant={"flushed"}
              boxShadow={
                formik.errors.negara ? "0px 1px 0px 0px #E53E3E !important" : ""
              }
            />
            <FormErrorMessage color={"red.400"}>
              {formik.errors.negara}
            </FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <FormControl isInvalid={formik.errors.perusahaan ? true : false}>
          <Input
            name="perusahaan"
            onChange={formik.handleChange}
            value={formik.values.perusahaan}
            className="inputContact"
            placeholder={landingData.contact[lang].form.company}
            variant={"flushed"}
            boxShadow={
              formik.errors.perusahaan
                ? "0px 1px 0px 0px #E53E3E !important"
                : ""
            }
          />
          <FormErrorMessage color={"red.400"}>
            {formik.errors.perusahaan}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={formik.errors.message ? true : false}>
          <Textarea
            name="message"
            onChange={formik.handleChange}
            value={formik.values.message}
            className="inputContact"
            placeholder={landingData.contact[lang].form.message}
            variant={"flushed"}
            boxShadow={
              formik.errors.message ? "0px 1px 0px 0px #E53E3E !important" : ""
            }
          />
          <FormErrorMessage color={"red.400"}>
            {formik.errors.message}
          </FormErrorMessage>
        </FormControl>
      </VStack>
    </form>
  );
}
