const policyData = {
  id: [
    {
      title: "Prototyping dan Diskusi",
      items: [
        " Distro Studio akan membuat desain prototype terlebih dahulu sesuai dengan kebutuhan yang telah disepakati dengan klien.",
        "Setelah pembuatan prototype, akan dilakukan diskusi dengan klien untuk menetapkan backlog (daftar kebutuhan) yang jelas.",
      ],
    },
    {
      title: "Penentuan Modul dan Batasan",
      items: [
        " Distro Studio akan menentukan modul-modul yang akan dikerjakan sesuai dengan kebutuhan yang telah ditentukan dalam diskusi dengan klien.",
        "Setiap modul akan memiliki batasan yang jelas dan tidak akan dikerjakan di luar ruang lingkup yang telah ditentukan.",
      ],
    },
    {
      title: "Pembayaran",
      items: [
        "Pembayaran dapat disepakati bersama antara Distro Studio dan klien.",
        "Opsi pembayaran dapat berupa pembayaran sebesar 50% di muka dan 50% setelah penyelesaian proyek, atau pembayaran sesuai dengan timeline yang telah disepakati.",
        "Jika menggunakan pembayaran sesuai dengan timeline, Distro Studio akan membuat timeline proyek yang akan dilaporkan kepada klien secara berkala.",
      ],
    },
    {
      title: "Pemeliharaan",
      items: [
        "Distro Studio akan memberikan layanan pemeliharaan selama 3 bulan setelah penyelesaian proyek.",
        "Setelah periode pemeliharaan berakhir, tanggung jawab atas pemeliharaan akan dilepas kepada klien.",
      ],
    },
    {
      title: "Perubahan dan Perjanjian Tambahan",
      items: [
        "Perubahan terhadap syarat dan ketentuan ini harus disetujui secara tertulis oleh kedua belah pihak.",
        "Perjanjian tambahan atau perubahan lain terhadap proyek harus disepakati secara tertulis oleh kedua belah pihak.",
      ],
    },
    {
      title: "Pemisahan Tanggung Jawab",
      items: [
        "Distro Studio bertanggung jawab untuk memberikan solusi teknis sesuai dengan permintaan klien.",
        "Klien bertanggung jawab untuk menyediakan semua informasi yang diperlukan dan memberikan umpan balik yang tepat waktu.",
      ],
    },
    {
      title: "Penyelesaian Sengketa",
      items: [
        "Setiap sengketa yang timbul akan diselesaikan secara musyawarah antara kedua belah pihak.",
      ],
    },
  ],
  en: [
    {
      title: "Prototyping and Discussion",
      items: [
        "Distro Studio will create a prototype design based on the agreed-upon requirements with the client.",
        "After creating the prototype, a discussion will be held with the client to establish a clear backlog (list of requirements).",
      ],
    },
    {
      title: "Module Determination and Limitation",
      items: [
        "Distro Studio will determine the modules to be worked on according to the requirements specified in the discussion with the client.",
        "Each module will have clear limitations and will not be worked on beyond the defined scope.",
      ],
    },
    {
      title: "Payment",
      items: [
        "Payment can be agreed upon by both Distro Studio and the client.",
        "Payment options can include a 50% upfront payment and 50% upon project completion, or payment according to an agreed-upon timeline.",
        "If using payment according to the timeline, Distro Studio will create a project timeline that will be reported to the client regularly.",
      ],
    },
    {
      title: "Maintenance",
      items: [
        "Distro Studio will provide maintenance services for 3 months after project completion.",
        "After the maintenance period ends, responsibility for maintenance will be transferred to the client.",
      ],
    },
    {
      title: "Changes and Additional Agreements",
      items: [
        "Changes to these terms and conditions must be agreed upon in writing by both parties.",
        "Additional agreements or changes to the project must be agreed upon in writing by both parties.",
      ],
    },
    {
      title: "Responsibility Separation",
      items: [
        "Distro Studio is responsible for providing technical solutions according to the client's request.",
        "The client is responsible for providing all necessary information and providing timely feedback.",
      ],
    },
    {
      title: "Dispute Resolution",
      items: [
        "Any disputes that arise will be resolved through mutual agreement between both parties.",
      ],
    },
  ],
};

export default policyData;
