import {
  Box,
  Button,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { getLang } from "../lib/lang";
import landingData from "../constant/landingData";
import Container from "../components/Container";
import { Link } from "react-router-dom";
import { RiArrowRightLine } from "@remixicon/react";
import { useGSAP } from "@gsap/react";
import scrollTriggerConfig from "../lib/scrollTriggerConfig";
import gsap from "gsap";
import qualityLottie from "../../public/vectors/quality.json";
import Lottie from "lottie-react";
import { useRef } from "react";

export default function Quality() {
  const lang = getLang();
  const data = landingData.quality[lang];
  const containerRef = useRef(null);

  // Animation
  useGSAP(
    () => {
      gsap.from([".banner"], {
        scrollTrigger: scrollTriggerConfig(".banner"),
        opacity: 0,
      });

      gsap.from([".qualityLeft"], {
        scrollTrigger: scrollTriggerConfig(".banner"),
        x: -300,
        opacity: 0,
      });

      gsap.from([".qualityCenter"], {
        scrollTrigger: scrollTriggerConfig(".banner"),
        scale: 0.5,
        opacity: 0,
      });

      gsap.from([".qualityRight"], {
        scrollTrigger: scrollTriggerConfig(".banner"),
        x: 300,
        opacity: 0,
      });
    },
    { scope: containerRef }
  );

  return (
    <VStack ref={containerRef} id="qualitySection">
      <Container align={"stretch"}>
        <HStack
          flexDir={["column", null, null, "row"]}
          align={"center"}
          bg={
            "linear-gradient(to bottom right, var(--p400), var(--p500), var(--p600))"
          }
          overflow={"clip"}
          color={"white"}
          borderRadius={12}
          px={[8, 10, 12]}
          py={10}
          gap={12}
          className="banner"
        >
          <Image
            className="qualityLeft"
            flexShrink={0}
            src="/vectors/badge.svg"
            alt="Badge Icon"
            w={"140px"}
            loading="lazy"
          />

          <VStack align={"stretch"} className="qualityCenter">
            <Text
              // fontSize={[28, 32, 36]}
              fontWeight={"500 !important"}
              lineHeight={1.2}
              mb={2}
              className="sectionTitle"
              textAlign={["center", null, null, "left"]}
            >
              {data.title}
            </Text>

            <Text
              opacity={0.6}
              mb={6}
              textAlign={["center", null, null, "left"]}
              fontSize={[16, 18, 20]}
              maxW={"600px"}
            >
              {data.narrative}
            </Text>

            <Button
              flexShrink={0}
              className="clicky"
              pl={8}
              pr={7}
              h={"50px"}
              w={["100%", null, null, "fit-content"]}
              as={Link}
              to={"/contact"}
              bg={"white"}
              _hover={{ bg: "whiteAlpha.900" }}
              _active={{ bg: "whiteAlpha.800" }}
              color={"p.500"}
            >
              <HStack
                justify={["center", null, null, "space-between"]}
                w={"100%"}
              >
                <Text pr={2} fontSize={18}>
                  {landingData.contact[lang].title}
                </Text>
                <Icon as={RiArrowRightLine} fontSize={24} />
              </HStack>
            </Button>
          </VStack>

          <Box
            className="qualityRight"
            flexShrink={0}
            w={"250px"}
            ml={"auto"}
            mr={["auto", null, null, "-80px"]}
            mb={["auto", null, null, "-80px"]}
          >
            <Lottie animationData={qualityLottie} loop={true} width={"100%"} />
          </Box>
        </HStack>
      </Container>
    </VStack>
  );
}
